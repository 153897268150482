import React, { useState, useEffect } from "react";
import axios from "axios";
import { CgSmileNone } from "react-icons/cg";
import { useSelector } from "react-redux";

const ChatList = ({
  activeChat,
  setActiveChat,
  setOpenChatList,
  openChatList,
  setNewAction,
  newAction
}) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const userProfile = useSelector((state) => state.user.data);
  const userId = userProfile?.user?._id;

  // Fetch contacts on component mount and whenever userId changes
  useEffect(() => {
    const getContacts = async () => {
      if (newAction) {
        // setLoading(true);
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/profile/contact/${userId}`
          );
          console.log(res);
          if (res.status === 200) {
            setContacts(res.data.data);
            setLoading(false);
            setNewAction(false); // Reset newAction after fetching
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    };
    getContacts();
  }, [userId, newAction, setNewAction]);
  // Function to update the order of contacts locally
  const updateContactOrder = (updatedContacts) => {
    setContacts(updatedContacts);
  };

  return (
    <div
      className={`w-full lg:md:border-r lg:w-1/5 bg-[#f6f6f6] text-[#35B900] h-full p-4 lg:block ${openChatList ? "block" : "hidden"
        }`}
    >
      <div className="flex items-center justify-between pb-4 border-b border-gray-700">
        <h2 className="lg:text-xl text-lg font-bold">Kişiler Listesi </h2>
      </div>
      {loading ? (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <div className="loader"></div>
        </div>
      ) : contacts.length === 0 ? (
        <div className="text-gray-800 flex justify-center w-full h-full items-center">
          Kişi bulunamadı{" "}
          <CgSmileNone className="lg:md:text-3xl text-2xl ps-2" />
        </div>
      ) : (
        contacts.map((contact, index) => (
          <div
            key={index}
            className={`hover:bg-[#ddd] p-4 border-b cursor-pointer ${activeChat === contact._id ? "bg-[#d6f5ca]" : ""
              }`}
            onClick={() => {
              setActiveChat(contact._id);
              setOpenChatList(false);
            }}
          >
            <div className="font-semibold lg:text-[16px] text-[16px] flex items-center gap-x-2">
              <div>
                {contact.profile_picture ? (
                  <img
                    src={contact.profile_picture}
                    alt="profile"
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <h2 className="w-10 h-10 rounded-full bg-[#35B900] text-[#fff] uppercase text-[18px] text-center flex items-center justify-center">
                    {contact.first_name[0] + contact.lastName[0]}
                  </h2>
                )}
              </div>
              <h2>
                {contact.first_name} {contact.lastName}
              </h2>
            </div>
            <div className="text-gray-700 md:text-[14px] ">
              konuşmaya devam et...
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ChatList;
