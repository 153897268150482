import { IoIosStarOutline } from "react-icons/io";
import { CiDollar, CiLock } from "react-icons/ci";
import { FaPeopleGroup } from "react-icons/fa6";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { IoPeopleSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

export const Benefit = () => {
  return (
    <div className="w-full bg-[#90bc8242] px-0 py-[45px]">
      <div className="max-w-[1400px]  flex overflow-x-hidden items-center justify-around px-4 lg:px-16 mx-auto my-0">
        <div className="w-full">
          <h1 className="text-[rgb(53,185,0)] xs:text-lg lg:md:text-3xl capitalize mb-5   font-bold">
            KULLANICILAR NEDEN KOCFREELANCING'İ TERCİH EDİYOR?
          </h1>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <IoIosStarOutline className="text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">Üye olma ücreti yok.</p>
          </div>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <CiDollar className=" text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">
              İşinizi teslim alıp onay verene kadar paranız hesabınızda
              bekletilir.
            </p>
          </div>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <FaPeopleGroup className=" text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">
              Geniş ve profesyonel kullanıcı ağına sahiptir.
            </p>
          </div>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <MdOutlineMiscellaneousServices className=" text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">
              Kullanıcı sözleşmeleri ve doğrulamaları ile birlikte, çalışan ve
              işveren arasında güven bağı oluşturulur.
            </p>
          </div>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <CiLock className=" text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">
              Verilerinizi ve gizliliğinizi özenle korumaya yönelik adımlar
              atıyoruz.
            </p>
          </div>
          <div className=" flex items-center gap-x-4 text-[rgb(92,91,91)]  text-left mb-[25px] ">
            <div className="">
              {" "}
              <IoPeopleSharp className=" text-2xl" />{" "}
            </div>
            <p className="lg:md:text-xl text-[16px]">
              İhtiyacınız olduğu anda her türlü destek ile buradayız.
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center my-2">
        <Link
          className=" rounded-3xl  py-3 px-8    bg-primary hover:bg-opacity-90 transition-all border !text-white text-center active:scale-95 "
          to="/categories"
        >
          Şimdi Keşfet
        </Link>
      </div>
    </div>
  );
};
