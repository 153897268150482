import React, { useState, useEffect } from "react";
import { FaRegBookmark } from "react-icons/fa";
import {
  MdDelete,
  MdEdit,
  MdOutlineLocationOn,
  MdVerified,
} from "react-icons/md";
import { tr } from "date-fns/locale";
import { GrMapLocation } from "react-icons/gr";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Description from "../ui/Description";
import { useNavigate } from "react-router-dom";
import { VscUnverified } from "react-icons/vsc";
import { Rating, StickerStar } from "@smastrom/react-rating";
import { AnimatePresence } from "framer-motion";
import DeleteModal from "../modals/DeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { cancelJob, deleteJob, getJobs, getProfile } from "../../axios/axios";
import JobsModal from "../modals/JobsModal";
import {
  addBookmark,
  deleteBookmark,
  getBookmarks,
  getMyJobs,
} from "../../axios/axios";
import { MdBookmarkAdded } from "react-icons/md";
import { formatDistance } from "date-fns";
import { setLoading } from "../../redux/reducers/loadingSlice";

const ProjectCard = ({
  data,
  index,
  length,
  myJob,
  isBookmark,
  userProfile,
}) => {
  const {
    _id,
    createdAt,
    title,
    status,
    budget,
    deadline,
    project_size,
    skills,
    job_description,
    profile,
    user,
    category,
    subCategory,
    hasBeenBookmarked,
  } = isBookmark ? data?.offer : data;

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [showDeleteExperienceModal, setShowDeleteExperienceModal] =
    React.useState(null);
  const loading = useSelector((state) => state.loading.loading);

  const handleDeleteExperience = async () => {
    await dispatch(setLoading(true));
    if (status === "open") {
      await dispatch(
        deleteJob({
          dynamicParams: { jobId: showDeleteExperienceModal },
          bodyData: null,
        })
      )
        .then(() => {
          // After adding experience, fetch the updated profile data
          dispatch(getJobs({ user_id: userProfile?.user?._id, search: "" }));
          dispatch(getBookmarks(userProfile?.user?._id));
          dispatch(getMyJobs(userProfile?.user?._id));
        })
        .then(() => {
          // Once profile is fetched, reset loading state and close modal
          setShowDeleteExperienceModal(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      await dispatch(
        cancelJob({
          dynamicParams: { jobId: showDeleteExperienceModal },
          bodyData: null,
        })
      )
        .then(() => {
          // After adding experience, fetch the updated profile data
          dispatch(getJobs({ user_id: userProfile?.user?._id, search: "" }));
          dispatch(getBookmarks(userProfile?.user?._id));
          dispatch(getMyJobs(userProfile?.user?._id));
        })
        .then(() => {
          // Once profile is fetched, reset loading state and close modal
          setShowDeleteExperienceModal(null);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    await dispatch(setLoading(false));
  };
  const handleAddBookmark = async (id) => {
    await dispatch(setLoading(true));
    await dispatch(
      addBookmark({
        dynamicParams: {},
        bodyData: {
          user: userProfile?.user?._id,
          offer: id,
        },
      })
    )
      .then(() => dispatch(getProfile()))
      .then(async () => {
        // After adding experience, fetch the updated profile data
        await dispatch(
          getJobs({ user_id: userProfile?.user?._id, search: "" })
        );
        await dispatch(getBookmarks(userProfile?.user?._id));
        await dispatch(getMyJobs(userProfile?.user?._id));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await dispatch(setLoading(false));
  };
  const handleDeleteBookmark = async (id) => {
    await dispatch(setLoading(true));
    await dispatch(
      deleteBookmark({
        dynamicParams: { bookmarkId: id },
        bodyData: null,
      })
    )
      .then(() => dispatch(getProfile()))
      .then(async () => {
        // After adding experience, fetch the updated profile data
        await dispatch(
          getJobs({ user_id: userProfile?.user?._id, search: "" })
        );
        await dispatch(getBookmarks(userProfile?.user?._id));
        await dispatch(getMyJobs(userProfile?.user?._id));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await dispatch(setLoading(false));
  };
  const [showEditJobModal, setShowEditJobModal] = useState(null);

  return (
    <div
      className={`w-full project-card  cursor-pointer hover:bg-gray-100 group transition-all p-3 shadow-none ${
        length - 1 === index
          ? "border-b-0 rounded-bl-3xl rounded-br-3xl"
          : "border-b"
      }`}
      onClick={() => navigate(`/job/${_id}`)}
      onMouseUp={(e) => e.button === 1 && window.open(`/job/${_id}`, "_blank")}
      style={{ padding: "1rem" }}
    >
      <div className="flex justify-between items-center">
        <p className="break-all text-sm text-secondary">
          {createdAt &&
            (({ timestamp }) => (
              <span>
                {formatDistance(new Date(timestamp), new Date(), {
                  addSuffix: true,
                  locale: tr,
                })}
              </span>
            ))({ timestamp: createdAt })}
        </p>
        {myJob || user === userProfile?.user?._id ? (
          status === "open" && (
            <div className="flex items-center gap-x-2">
              <div
                className="rounded-full p-[6px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all text-sm lg:md:text-md"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event from bubbling up
                  setShowEditJobModal(_id);
                }}
              >
                <MdEdit className="text-primary text-lg" />
              </div>
              <div
                className="rounded-full p-[6px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all text-sm lg:md:text-md"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event from bubbling up
                  setShowDeleteExperienceModal(_id);
                }}
              >
                <MdDelete className="text-primary text-lg" />
              </div>
            </div>
          )
        ) : hasBeenBookmarked || isBookmark ? (
          <MdBookmarkAdded
            className="text-primary text-2xl cursor-pointer transition-all hover:opacity-90"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              if (isBookmark) {
                handleDeleteBookmark(data._id);
              } else {
                handleDeleteBookmark(data.bookmarkId);
              }
            }}
          />
        ) : (
          <FaRegBookmark
            className="text-primary text-xl cursor-pointer transition-all hover:opacity-90"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              handleAddBookmark(_id);
            }}
          />
        )}
      </div>
      <div className="space-y-2  mb-2">
        <h2 className="text-xl font-semibold group-hover:text-primary break-all">
          {title}
        </h2>
        <div className="flex items-center gap-x-2 flex-wrap gap-y-2">
          <button className="rounded-3xl px-4 py-1 text-xs lg:md:text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 whitespace-nowrap">
            {status === "open"
              ? "Açık"
              : status === "inprogress"
              ? "Devam etmekte "
              : status === "completed"
              ? "Tamamlandı"
              : status === "canceled"
              ? "İptal edildi"
              : status === "invited"
              ? "Davet edildi"
              : status}
          </button>
          <button className="rounded-3xl px-4 py-1 text-xs lg:md:text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 whitespace-nowrap">
            {subCategory?.name}
          </button>
          <button className="rounded-3xl px-4 py-1 text-xs lg:md:text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 whitespace-nowrap">
            {category?.name}
          </button>
        </div>
      </div>
      <div
        className="break-all text-sm text-secondary tooltip cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          navigator.clipboard.writeText(_id);
          const tooltip = e.target;
          tooltip.innerText = "Kopyalandı";
          setTimeout(() => (tooltip.innerText = `#${_id}`), 2000);
        }}
      >
        İş ID : <span className="font-medium">#{_id}</span>
      </div>
      <div className="lg:md:flex items-center mt-3 space-y-2 lg:md:space-y-0 ">
        <p className="break-all   text-secondary ">
          Ort. bütçe : <span className="font-medium">₺{budget}</span> |
        </p>

        <p className="break-all   text-secondary lg:md:ml-1">
          Ort. süre : <span className="font-medium">{deadline} gün</span> |
        </p>
        <p className="break-all   text-secondary lg:md:ml-1">
          Proje boyutu : <span className="font-medium">{project_size} </span>
        </p>
      </div>
      <Description
        description={job_description}
        className="my-3 text-green-900"
      />
      {!myJob && (
        <div className="lg:md:flex space-y-2 lg:md:space-y-0 justify-start gap-x-6 items-center">
          {profile?.payment_verified ? (
            profile?.payment_verified
          ) : user?.profile?.payment_verified ? (
            <div className="flex items-center gap-x-1 text-secondary">
              <RiVerifiedBadgeFill /> Ödeme Doğrulandı{" "}
            </div>
          ) : (
            <div className="flex items-center gap-x-1 text-secondary">
              <VscUnverified /> Ödeme Doğrulanmamış{" "}
            </div>
          )}

          <div className=" text-secondary flex items-center gap-x-2 font-medium">
            <Rating
              style={{ maxWidth: 100 }}
              value={
                profile?.client_overall_reviews
                  ? profile?.client_overall_reviews
                  : user?.profile?.client_overall_reviews ?? 0
              }
              readOnly
              itemStyles={{
                itemShapes: StickerStar,
                activeFillColor: "#35B900",
                inactiveFillColor: "#cecece",
              }}
            />

            <p className="break-all font-medium  text-secondary">
              {" "}
              {profile?.client_overall_reviews
                ? profile?.client_overall_reviews?.toFixed(2)
                : user?.profile?.client_overall_reviews?.toFixed(2)}
            </p>
            <p>
              (
              {profile?.client_reviews?.length
                ? profile?.client_reviews?.length
                : user?.profile?.client_reviews?.length ?? 0}{" "}
              yorum)
            </p>
          </div>
          <p className="break-all text-md  text-secondary">
            <span className="font-medium">
              ₺
              {profile?.amount_spent
                ? profile?.amount_spent
                : user?.profile?.amount_spent ?? 0}
            </span>{" "}
            harcanan
          </p>
          <p className="break-all text-md  text-secondary">
            <span className="font-medium">
              {profile?.total_hired
                ? profile?.total_hired
                : user?.profile?.total_hired ?? 0}
            </span>{" "}
            işe alım
          </p>
          <p className="break-all flex items-center">
            <GrMapLocation className="text-md mr-1 font-medium text-secondary" />
            <span className="text-md text-secondary ">
              {profile?.city ? profile?.city : user?.profile?.city},{" "}
              {profile?.country ? profile?.country : user?.profile?.country}
            </span>
          </p>
        </div>
      )}
      <div className="flex flex-wrap gap-x-2  gap-y-2 my-3">
        {skills?.map((element, idx) => (
          <div key={`skillset-${idx}`}>
            <button className="rounded-3xl px-3 py-1  bg-gray-400 bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
              {element?.name}
            </button>
          </div>
        ))}
      </div>
      <p className="break-all text-md  text-secondary">
        Teklifler :{" "}
        <span className="font-medium">{data?.proposals?.length ?? 0} </span>
      </p>
      {data?.proposals?.some(
        (proposal) => proposal.created_by === userProfile?.user?._id
      ) && (
        <p className="break-all lg:md:text-md text-sm  text-secondary font-medium flex items-center gap-x-1 mt-1">
          <MdVerified /> Bu iş için teklifinizi zaten gönderdiniz
        </p>
      )}
      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showEditJobModal && (
          <JobsModal
            setShowJobsModal={setShowEditJobModal}
            showJobsModal={showEditJobModal}
            userProfile={userProfile}
            isEdit={true}
            initialData={{
              title: title,
              budget: budget,
              deadline: deadline,
              project_size: project_size,
              job_description: job_description,
              skills: skills,
              category: category,
              subCategory: subCategory,
              user: profile?.user,
            }}
          />
        )}
        {showDeleteExperienceModal && (
          <DeleteModal
            loading={loading}
            onClose={() => setShowDeleteExperienceModal(null)}
            onConfirm={() => handleDeleteExperience()}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProjectCard;
