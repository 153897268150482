import React, { useEffect, useState } from "react";
import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";

const Terms = () => {
  return (
    <div className="bg-[#111822] overflow-x-hidden">
      <Navbar />
      <div className="mt-[88px] mx-auto max-w-[1400px] w-[95vw] ">
        <div className="w-full py-[10vh] lg:py-16  ">
          <div className="flex items-center justify-center">
            <img
              src="/assets/img/app.jpeg"
              alt="Veri bulunamadı"
              className="rounded-3xl"
            />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Terms;
