import React, { useEffect, useState } from "react";
import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptProposal,
  cancelJob,
  getHires,
  markAsCompleted,
  requestCompletion,
} from "../../axios/axios";
import { MdOutlineLocationOn } from "react-icons/md";
import { Rating, StickerStar } from "@smastrom/react-rating";
import { VscUnverified } from "react-icons/vsc";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Description from "../../components/ui/Description";
import { formatDistance } from "date-fns";

import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import DeleteModal from "../../components/modals/DeleteModal";
import JobsModal from "../../components/modals/JobsModal";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import RatingsModal from "../../components/modals/RatingsModal";
import axios from "axios";
import { tr } from "date-fns/locale";
import ConfirmationModal from "../../components/modals/ConfirmationModal";

const MyFreelancers = () => {
  const [data, setData] = useState([]);
  const loading = useSelector((state) => state.loading.loading);
  const userProfile = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [showHireNowModal, setShowHireNowModal] = useState(null);
  const [showRatingsModal, setShowRatingsModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [active, setActive] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));

      // Ensure userProfile is defined and contains user._id
      if (userProfile && userProfile.user && userProfile.user._id) {
        const response = await dispatch(
          getHires({
            userId: userProfile.user._id,
            search: searchTerm,
            status: active,
          })
        );
        setData(response?.payload?.data);
      }

      dispatch(setLoading(false));
    };

    fetchData();
  }, [
    active,
    dispatch,
    searchTerm,
    userProfile,
    showHireNowModal,
    showRatingsModal,
  ]);

  const handleCheckboxChange = (status) => {
    // Check if status already exists in active array
    const index = active.indexOf(status);
    if (index === -1) {
      // If not, add it
      setActive([...active, status]);
    } else {
      // If exists, remove it
      setActive(active.filter((s) => s !== status));
    }
  };
  const [showCancelJobModal, setShowCancelJobModal] = useState(null);
  const handleCancelJob = async () => {
    await dispatch(setLoading(true));
    await dispatch(
      cancelJob({
        dynamicParams: { jobId: showCancelJobModal },
        bodyData: null,
      })
    )
      .then(async () => {
        // After adding Proposal, fetch the updated profile data
        const response = await dispatch(
          getHires({
            userId: userProfile.user._id,
            search: searchTerm,
            status: active,
          })
        );
        setData(response?.payload?.data);
      })
      .then(() => {
        // Once profile is fetched, reset loading state and close modal
        setShowCancelJobModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await dispatch(setLoading(false));
  };
  const [proposals, setProposals] = useState({});
  const [loadingProposals, setLoadingProposals] = useState({});

  useEffect(() => {
    const fetchProposals = async (proposalIds) => {
      setLoadingProposals((prev) => ({
        ...prev,
        ...proposalIds.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
      }));

      const proposalData = {};
      await Promise.all(
        proposalIds.map(async (proposalId) => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/proposal/${proposalId}`
            );
            proposalData[proposalId] = response.data;
          } catch (error) {
            console.error("Error fetching proposal data:", error);
          } finally {
            setLoadingProposals((prev) => ({ ...prev, [proposalId]: false }));
          }
        })
      );

      setProposals((prev) => ({ ...prev, ...proposalData }));
    };

    const proposalIdsToFetch = [];
    data?.forEach((item) => {
      if (item.status === "invited" && item.proposals?.length > 0) {
        proposalIdsToFetch.push(item.proposals?.[0]); // Only the first proposal ID
      }
    });

    if (proposalIdsToFetch.length > 0) {
      fetchProposals(proposalIdsToFetch);
    }
  }, [data]);
  const handleAcceptProposal = async ({ proposalId, jobId }) => {
    await dispatch(setLoading(true));
    await dispatch(
      acceptProposal({
        dynamicParams: { proposalId: proposalId, jobId: jobId },
        bodyData: {},
      })
    )
      .then(async () => {
        // After adding Proposal, fetch the updated profile data
        const response = await dispatch(
          getHires({
            userId: userProfile.user._id,
            search: searchTerm,
            status: active,
          })
        );
        setData(response?.payload?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    await dispatch(setLoading(false));
    await dispatch(setShowConfirmationModal(null));
  };
  console.log(data, loading);

  // Toggle show more İncelemeler
  const [displayedRemainingReviews, setDisplayedRemainingReviews] =
    useState(12);

  const loadMoreReviews = () => {
    setDisplayedRemainingReviews((prev) => prev + 12);
  };

  const showLessReviews = () => {
    setDisplayedRemainingReviews(12);
  };
  const sendMessageToFreelancer = async (freelancerEmail) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/offer/initiateChat/${userProfile?.user?._id}`,
        { freelancerEmail }
      );

      if (res.status === 200) {
        console.log(res.data);
        navigate(`/messages`);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ProtectedRoute>
      <Navbar />
      <div className=" max-w-[1400px] lg:px-4 my-28 mx-2 lg:md:mx-auto  lg:md:grid grid-cols-4 gap-x-6">
        <div className="col-span-3">
          <div className="relative flex items-center w-full border h-12 rounded-3xl  bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              className="peer h-full w-full outline-none text-sm text-secondary pr-2"
              type="text"
              id="search"
              placeholder=" İş ara"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <div className="col-span-1 lg:md:hidden block">
            {/* Durum filters */}
            <p className="break-all  my-4  font-medium text-2xl">Durum</p>
            <div className="w-full  border mt-3 rounded-3xl  bg-[#ffffff] overflow-hidden py-3 px-6">
              {/* Checkbox options */}
              {["inprogress", "completed", "canceled", "invited"].map(
                (status, index) => (
                  <div
                    className="flex items-center my-2"
                    key={index}
                    onClick={() => handleCheckboxChange(status)}
                  >
                    <label
                      className={`relative flex cursor-pointer items-center rounded-full mr-2 ${
                        active.includes(status) && "bg-primary"
                      }`}
                      htmlFor={`checkbox-${index}`}
                      data-ripple-dark="true"
                    >
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-primary transition-all before:absolute checked:border-primary checked:bg-primary"
                        checked={active.includes(status)}
                      />
                      <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          strokeWidth={1}
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </label>
                    <p className="break-all text-secondary font-medium">
                      {status === "open"
                        ? "Açık"
                        : status === "inprogress"
                        ? "Devam etmekte "
                        : status === "completed"
                        ? "Tamamlandı"
                        : status === "canceled"
                        ? "İptal edildi"
                        : status === "invited"
                        ? "Davet edildi"
                        : status}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
          <p className="break-all lg:md:my-8 my-4 font-medium  text-xl lg:md:text-3xl">
            İşe Aldıklarım ({data?.length ?? 0})
          </p>

          <div className="border rounded-3xl   justify-center    mb-14 mx-auto">
            {loading || loadingProposals.global ? (
              <div className="rounded-3xl max-w-[1400px]  lg:px-4  flex items-center justify-center h-[80vh]  mb-14  mx-auto">
                <div className="loader"></div>
              </div>
            ) : data?.length > 0 ? (
              [...data]
                ?.reverse()
                ?.slice(0, displayedRemainingReviews)
                ?.map((item, index) => (
                  <div
                    className={`w-full project-card pb-6  group transition-all p-3 shadow-none ${
                      data?.length - 1 === index ? "border-b-0 " : "border-b"
                    }`}
                    key={index}
                  >
                    <div className="flex justify-between items-center">
                      <p className="break-all text-sm text-secondary">
                        {" "}
                        {item?.createdAt &&
                          (({ timestamp }) => (
                            <span>
                              {formatDistance(new Date(timestamp), new Date(), {
                                addSuffix: true,
                                locale: tr,
                              })}
                            </span>
                          ))({ timestamp: item?.createdAt })}{" "}
                        yayınlandı
                      </p>
                    </div>
                    <div className="space-y-2 mb-1">
                      <h2
                        className="text-xl font-semibold cursor-pointer hover:text-primary break-all"
                        onClick={() => navigate(`/job/${item?._id}`)}
                      >
                        {item?.title}
                      </h2>{" "}
                      <div className="flex items-center gap-x-2 flex-wrap gap-y-2">
                        <button className="rounded-3xl  px-4 py-1 text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                          {item?.status === "open"
                            ? "Açık"
                            : item?.status === "inprogress"
                            ? "Devam etmekte "
                            : item?.status === "completed"
                            ? "Tamamlandı"
                            : item?.status === "canceled"
                            ? "İptal edildi"
                            : item?.status === "invited"
                            ? "Davet edildi"
                            : item?.status}
                        </button>
                        <button className="rounded-3xl  px-4 py-1 text-sm bg-primary bg-opacity-[0.18] text-secondary text-center active:scale-95 ">
                          {item?.category?.name}
                        </button>
                      </div>
                    </div>
                    <div className="text-gray-500">#{item._id}</div>

                    <div className="flex items-center  ">
                      <p className="break-all  pt-3 text-secondary ">
                        Bütçe :{" "}
                        <span className="font-medium">
                          ₺{item?.budget ?? 0}
                        </span>{" "}
                        |
                      </p>

                      <p className="break-all  pt-3 text-secondary ml-1">
                        Süre :{" "}
                        <span className="font-medium">
                          {item?.deadline ?? 0} gün
                        </span>
                      </p>
                    </div>
                    <Description
                      description={item?.job_description}
                      className="py-3 text-green-900"
                    />

                    <p className="break-all text-md  text-secondary">
                      Serbest Çalışan :{" "}
                    </p>
                    <div>
                      <div className="lg:md:flex items-start justify-between mb-2">
                        <div
                          className="lg:md:flex items-center gap-x-2 cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/profile/${item?.hired_profile?.user?._id}`
                            )
                          }
                        >
                          {item?.hired_profile?.user?.profile_picture ? (
                            <div className="w-[78px] h-[78px] border rounded-full  relative">
                              <img
                                src={item?.hired_profile?.user?.profile_picture}
                                width={78}
                                height={78}
                                alt="profile picture"
                                className="object-cover rounded-full w-full h-full"
                              />
                            </div>
                          ) : (
                            <div className="w-[78px] h-[78px] border bg-primary flex items-center justify-center rounded-full hover:cursor-pointer relative">
                              <p className="break-all text-2xl text-white">
                                {(item?.hired_profile?.user?.first_name?.slice(
                                  0,
                                  1
                                ) ?? "") +
                                  (item?.hired_profile?.user?.lastName?.slice(
                                    0,
                                    1
                                  ) ?? "")}
                              </p>
                            </div>
                          )}

                          <div>
                            <p className="break-all text-xl text-secondary font-semibold">
                              {item?.hired_profile?.user?.first_name ??
                                "--" +
                                  " " +
                                  item?.hired_profile?.user?.lastName ??
                                "--"}{" "}
                            </p>

                            <div className="lg:md:flex items-center space-y-1 lg:md:space-y-0 gap-x-2">
                              <div className=" text-secondary flex items-center gap-x-2 font-medium">
                                <Rating
                                  style={{ maxWidth: 100 }}
                                  value={item?.hired_profile?.overall_rating}
                                  readOnly
                                  itemStyles={{
                                    itemShapes: StickerStar,
                                    activeFillColor: "#35B900",
                                    inactiveFillColor: "#cecece",
                                  }}
                                />

                                <p className="break-all font-medium  text-secondary">
                                  {" "}
                                  {item?.hired_profile?.overall_rating?.toFixed(
                                    2
                                  )}
                                </p>
                                <p>
                                  ({item?.hired_profile?.completed_projects}{" "}
                                  İncelemeler)
                                </p>
                              </div>
                              <p className="break-all flex items-center ">
                                <MdOutlineLocationOn className="w-6 h-6 mr-1 -ml-1 text-secondary" />
                                <span className="text-lg text-secondary">
                                  {item?.hired_profile?.city},{" "}
                                  {item?.hired_profile?.country}
                                </span>
                              </p>
                            </div>
                            <p className="break-all text-secondary font-medium ">
                              {item?.hired_profile?.category?.name} |{" "}
                              {item?.hired_profile?.sub_title}
                            </p>
                          </div>
                        </div>
                        <div>
                          <p className="break-all text-lg text-secondary  font-semibold mb-[2px]">
                            ₺{item?.hired_profile?.hourly_rate ?? 0}/sa
                          </p>
                        </div>
                      </div>
                      {item?.status === "inprogress" ? (
                        <div className="lg:flex space-y-2 lg:space-y-0 items-center justify-end gap-x-2">
                          <button
                            className="rounded px-4 py-2 border-red-400 border text-red-400 text-center active:scale-95 transition-all hover:bg-opacity-90"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowCancelJobModal(item?._id);
                            }}
                          >
                            İşi İptal Et
                          </button>{" "}
                          <button
                            className="rounded px-4 py-2 border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90 ml-1 lg:ml-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendMessageToFreelancer(
                                item?.hired_profile?.user?.email
                              );
                            }}
                          >
                            Sohbeti Başlat
                          </button>
                          <button
                            className="rounded px-4 py-2 border-primary border text-white bg-primary text-center active:scale-95 transition-all hover:bg-opacity-90"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRatingsModal(item);
                            }}
                          >
                            Tamamlandı olarak işaretle
                          </button>
                        </div>
                      ) : item?.status === "invited" ? (
                        <div
                          className={`p-2 ${
                            item?.proposals?.length > 0 ? "border-t" : ""
                          }`}
                        >
                          <p className="-ml-2 mb-2 text-secondary">
                            {item?.proposals?.length > 0
                              ? "Teklif :"
                              : "Serbest çalışandan henüz yanıt yok"}
                          </p>

                          {proposals?.[item?.proposals?.[0]] && (
                            <div>
                              {console.log(
                                proposals?.[item?.proposals?.[0]],
                                proposals?.[item?.proposals?.[0]]?.data,
                                "ssd2"
                              )}
                              <div className="mb-2">
                                <p className="break-all text-lg lg:text-2xl text-secondary font-semibold mb-[2px]">
                                  ₺
                                  {
                                    proposals?.[item?.proposals?.[0]]?.data
                                      ?.bid_amount
                                  }
                                </p>
                                <p className="break-all font-medium text-secondary">
                                  {
                                    proposals?.[item?.proposals?.[0]]?.data
                                      ?.delivery_time
                                  }{" "}
                                  gün içinde
                                </p>
                              </div>
                              <p className="break-all text-secondary ">
                                <Description
                                  description={
                                    proposals?.[item?.proposals?.[0]]?.data
                                      ?.proposal_description
                                  }
                                  maxLines={1}
                                  className="text-secondary"
                                />
                              </p>
                              <p className="break-all text-secondary mt-2">
                                {proposals?.[item?.proposals?.[0]]?.data
                                  ?.createdAt &&
                                  (({ timestamp }) => (
                                    <span>
                                      {formatDistance(
                                        new Date(timestamp),
                                        new Date(),
                                        {
                                          addSuffix: true,
                                          locale: tr,
                                        }
                                      )}
                                    </span>
                                  ))({
                                    timestamp:
                                      proposals?.[item?.proposals?.[0]]?.data
                                        ?.createdAt,
                                  })}
                              </p>
                            </div>
                          )}

                          <div className="lg:flex space-y-2 lg:space-y-0 items-center justify-end space-x-2">
                            <button
                              className="rounded px-4 py-2 border-red-400 border text-red-400 text-center active:scale-95 transition-all hover:bg-opacity-90"
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowCancelJobModal(item?._id);
                              }}
                            >
                              İşi İptal Et
                            </button>{" "}
                            <button
                              className="rounded px-4 py-2 border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90 ml-1 lg:ml-0"
                              onClick={(e) => {
                                e.stopPropagation();
                                sendMessageToFreelancer(
                                  item?.hired_profile?.user?.email
                                );
                              }}
                            >
                              Sohbeti Başlat
                            </button>
                            {item?.proposals?.length > 0 ? (
                              <button
                                className="rounded px-6 py-2 border-primary border text-white bg-primary text-center active:scale-95 transition-all hover:bg-opacity-90"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setShowConfirmationModal({
                                    proposalId: item?.proposals?.[0],
                                    jobId: item?._id,
                                  });
                                }}
                              >
                                Teklifi Kabul Et
                              </button>
                            ) : (
                              <button
                                className="rounded px-4 py-2 border-primary border text-white bg-primary text-center active:scale-95 transition-all hover:bg-opacity-90"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigate(`/job/${item?._id}`);
                                }}
                              >
                                Devam et
                              </button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex lg:flex-row flex-col-reverse space-y-2 lg:space-y-0 lg:items-center lg:justify-end lg:space-x-2">
                          <button
                            className="rounded px-4 py-2 border-primary border text-primary bg-white text-center active:scale-95 transition-all hover:bg-opacity-90 mt-2 lg:mt-0"
                            onClick={(e) => {
                              e.stopPropagation();
                              sendMessageToFreelancer(
                                item?.hired_profile?.user?.email
                              );
                            }}
                          >
                            Sohbeti Başlat
                          </button>
                          <button
                            className="rounded px-4 py-2 border-primary border text-white bg-primary text-center active:scale-95 transition-all hover:bg-opacity-90"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowHireNowModal(
                                item?.hired_profile?.user?._id
                              );
                            }}
                          >
                            Tekrar işe al
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <div className="flex items-center justify-center">
                <img
                  src="/assets/404.png"
                  width={400}
                  height={400}
                  alt="Veri bulunamadı"
                />
              </div>
            )}
            <div
              className={`flex items-center justify-center gap-x-2 col-span-3 `}
            >
              {data?.length > displayedRemainingReviews && (
                <div className="flex items-center justify-center my-4">
                  <button
                    className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                    onClick={loadMoreReviews}
                  >
                    Daha fazla yükle
                  </button>
                </div>
              )}
              {displayedRemainingReviews > 12 && (
                <div className="flex items-center justify-center my-4">
                  <button
                    className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                    onClick={showLessReviews}
                  >
                    Daha az göster
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-1 hidden lg:md:block">
          {/* Durum filters */}
          <p className="break-all lg:md:my-8 my-4  font-medium text-2xl">
            Durum
          </p>
          <div className="w-full h-1/2 border mt-6 rounded-3xl min-h-[50vh] bg-[#ffffff] overflow-hidden py-3 px-6">
            {/* Checkbox options */}
            {["inprogress", "completed", "canceled", "invited"].map(
              (status, index) => (
                <div
                  className="flex items-center my-2"
                  key={index}
                  onClick={() => handleCheckboxChange(status)}
                >
                  <label
                    className={`relative flex cursor-pointer items-center rounded-full mr-2 ${
                      active.includes(status) && "bg-primary"
                    }`}
                    htmlFor={`checkbox-${index}`}
                    data-ripple-dark="true"
                  >
                    <input
                      type="checkbox"
                      id={`checkbox-${index}`}
                      className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-primary transition-all before:absolute checked:border-primary checked:bg-primary"
                      checked={active.includes(status)}
                    />
                    <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3.5 w-3.5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        stroke="currentColor"
                        strokeWidth={1}
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </label>
                  <p className="break-all text-secondary font-medium">
                    {status === "open"
                      ? "Açık"
                      : status === "inprogress"
                      ? "Devam etmekte "
                      : status === "completed"
                      ? "Tamamlandı"
                      : status === "canceled"
                      ? "İptal edildi"
                      : status === "invited"
                      ? "Davet edildi"
                      : status}
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <Footer />
      {console.log(showConfirmationModal)}
      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showCancelJobModal && (
          <DeleteModal
            loading={loading}
            onClose={() => setShowCancelJobModal(null)}
            onConfirm={() => handleCancelJob()}
          />
        )}
        {showRatingsModal && (
          <RatingsModal
            setShowRatingsModal={setShowRatingsModal}
            showRatingsModal={showRatingsModal}
            userProfile={userProfile}
          />
        )}
        {showHireNowModal && (
          <JobsModal
            setShowJobsModal={setShowHireNowModal}
            showJobsModal={showHireNowModal}
            userProfile={userProfile}
            isHireNow={true}
          />
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            title="Teklifi kabul etmek"
            loading={loading}
            onClose={() => setShowConfirmationModal(null)}
            onConfirm={() => handleAcceptProposal(showConfirmationModal)}
          />
        )}
      </AnimatePresence>
    </ProtectedRoute>
  );
};

export default MyFreelancers;
