import React from "react";

export const AdvertContent = () => {
  return (
    <div className="w-full bg-[whitesmoke] overflow-x-hidden px-4 py-8 lg:md:py-16">
      <div className="lg:max-w-[1100px]  grid lg:md:grid-cols-2 grid-cols-1 gap-6 items-center justify-center max-w-[500px] w-full mx-auto ">
        <img
          src="./assets/img/freelancer2.jpeg"
          alt="freelancer2.jpeg"
          className="h-auto rounded-[5px] order-1 md:order-2"
        />
        <div className="w-full mt-6 lg:md:mt-0 order-2 lg:md:order-1">
          <h1 className="text-[rgb(53,185,0)] text-lg lg:md:text-3xl capitalize mb-5 lg:md:text-start text-center lg:md:pl-0 pl-[15px] font-semibold lg:block hidden">
            KENDİ TARZIN DOĞRULTUSUNDA YETENEK BULABİLİRSİN
          </h1>
          <h1 className="text-[rgb(53,185,0)] text-lg lg:md:text-3xl capitalize mb-5 lg:md:text-start text-center lg:md:pl-0 pl-[15px] font-semibold block lg:hidden">
            KENDİ TARZIN DOĞRULTUSUNDA <br />
            YETENEK BULABİLİRSİN
          </h1>
          <p className="text-[rgb(92,91,91)] lg:md:text-xl text-[16px] lg:md:leading-normal leading-relaxed lg:md:pl-0 pl-[15px] lg:md:text-start ">
            Geniş ve bağımsız çalışan kullanıcı ağı sayesinde, işlerin için
            kendi tarzın doğrultusunda bir yetenek bulabilirsin.
          </p>
        </div>
      </div>
    </div>
  );
};
