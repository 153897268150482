import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../components/layouts/Navbar";
import Footer from "../../components/layouts/Footer";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { getTransactions } from "../../axios/axios";
import { parseISO, format } from "date-fns";
import { tr } from "date-fns/locale";
export default function Profile() {
  // Selecting necessary data from Redux store
  const userProfile = useSelector((state) => state.user?.data);
  const loading = useSelector((state) => state.loading?.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        // Fetch countries data
        const response = await dispatch(
          getTransactions(userProfile?.user?._id)
        );
        setData(response?.payload?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      dispatch(setLoading(false));
    };

    fetchData();
  }, [dispatch, userProfile]);
  console.log(data);

  const [displayedRemainingProjects, setDisplayedRemainingProjects] =
    useState(10);

  const loadMoreProjects = () => {
    setDisplayedRemainingProjects((prev) => prev + 10);
  };

  const showLessProjects = () => {
    setDisplayedRemainingProjects(10);
  };
  return (
    <ProtectedRoute>
      <Navbar />
      {loading ? (
        <div className="border rounded-3xl max-w-[1400px] flex items-center justify-center h-[80vh] my-28  mx-2 lg:md:mx-auto">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="mt-28 mx-auto max-w-[1400px]  w-[95vw]">
          <div className="border rounded-3xl w-full my-6 p-6 ">
            <p className="break-all text-xl lg:text-3xl font-medium">
              İşlem Geçmişi ({data?.length ?? 0})
            </p>
            <div className="rounded-lg border border-gray-200 shadow-md m-2 lg:md:m-5 overflow-x-auto">
              <table className="min-w-full border-collapse bg-white text-left text-sm text-gray-500">
                <thead className="bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      #
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      Tarih
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      IBAN Numarası
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      İşlem Tipi
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      Durum
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      Açıklama
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-4 font-medium text-gray-900"
                    >
                      Tutar
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                  {data?.length > 0 ? (
                    [...data]
                      .reverse()
                      .slice(0, displayedRemainingProjects)
                      .map((item, index) => (
                        <tr className="hover:bg-gray-50" key={index}>
                          <td className="px-6 py-4">{index + 1}</td>
                          <td className="flex gap-3 px-6 py-4 font-normal text-gray-900 whitespace-nowrap">
                            <div className="text-sm">
                              <div className="font-medium text-gray-700">
                                {item?.createdAt &&
                                  format(
                                    parseISO(item.createdAt),
                                    "dd MMMM yyyy HH:mm",
                                    { locale: tr }
                                  )}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.iban_number ? item?.iban_number : "0"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.type
                              ? item?.type === "withdrawal"
                                ? "Para çekme"
                                : item?.type === "add"
                                ? "Bakiye ekleme"
                                : item?.type
                              : "--"}
                          </td>
                          <td className="px-6 py-4">
                            <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-primary">
                              <span className="h-1.5 w-1.5 rounded-full bg-primary"></span>
                              {item?.status === "pending"
                                ? "Devam ediyor"
                                : item?.status === "denied"
                                ? "Reddedildi"
                                : item?.status === "canceled"
                                ? "Iptal edildi"
                                : item?.status === "approved"
                                ? "Onaylandı"
                                : item?.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {item?.reason ? item?.reason : "--"}
                          </td>
                          <td className="px-6 py-4">
                            ₺{item?.amount ? item?.amount : "0"}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4">
                        <div className="flex items-center justify-center">
                          <img
                            src="/assets/404.png"
                            width={400}
                            height={400}
                            alt="Veri bulunamadı"
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className={`flex items-center justify-center gap-x-2 `}>
                {data?.length > displayedRemainingProjects && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={loadMoreProjects}
                    >
                      Daha fazla yükle
                    </button>
                  </div>
                )}
                {displayedRemainingProjects > 10 && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={showLessProjects}
                    >
                      Daha az göster
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </ProtectedRoute>
  );
}
