import React, { useState } from "react";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";

const ChatApp = () => {
  const [activeChat, setActiveChat] = useState(null);
  const [openChatList, setOpenChatList] = useState(true);
  const [newAction, setNewAction] = useState();

  return (
    <div className="bg-[#f5f5f5] w-full mx-auto overflow-x-hidden">
      <div className="h-[100vh] border-[2px] border-[#e1e0e0] rounded-md flex flex-col lg:flex-row pt-[5.6rem] bg-[#f5f5f5] max-w-[1500px] w-full mx-auto">
        <ChatList
          activeChat={activeChat}
          setActiveChat={setActiveChat}
          setOpenChatList={setOpenChatList}
          openChatList={openChatList}
          setNewAction={setNewAction}
          newAction={newAction}
        />
        <ChatWindow
          activeChat={activeChat}
          setActiveChat={setActiveChat}
          setOpenChatList={setOpenChatList}
          openChatList={openChatList}
          setNewAction={setNewAction}
          newAction={newAction}
        />
      </div>
    </div>
  );
};

export default ChatApp;
