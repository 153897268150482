import React, { useEffect, useState } from "react";
import Modal from "../ui/Modal";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { addPayment, getProfile } from "../../axios/axios";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { useForm } from "react-hook-form";
import Select from "react-tailwindcss-select";
import toast from "react-hot-toast";

const OtpModal = ({
  setPaymentModal,
  userProfile,
  paymentModal,
  initialData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading.loading);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: initialData || {}, // Set default values from initialData if available
  });

  useEffect(() => {
    if (isEdit && initialData?.iban_number) {
      const ibanWithoutPrefix = initialData.iban_number.replace(/^TR/, "");
      setValue("iban_number", ibanWithoutPrefix);
    }
  }, [isEdit, initialData, setValue]);

  const onSubmit = (data) => {
    if (!bank) {
      toast.error("Lütfen bir banka seçiniz");
      return;
    }
    const ibanNumber = `TR${data.iban_number}`;
    dispatch(setLoading(true));
    dispatch(
      addPayment({
        dynamicParams: { userId: userProfile?.user?._id },
        bodyData: { ...data, iban_number: ibanNumber, bank_name: bank?.label },
      })
    )
      .then(() => dispatch(getProfile()))
      .then(() => {
        dispatch(setLoading(false));
        setPaymentModal(!isEdit ? false : null);
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setLoading(false));
      });
  };
  const banks = [
    { value: "Halkbank", label: "Halkbank" },
    { value: "Vakıfbank", label: "Vakıfbank" },
    { value: "Ziraat Bankası", label: "Ziraat Bankası" },
    { value: "Akbank", label: "Akbank" },
    { value: "Anadolubank", label: "Anadolubank" },
    { value: "Fibabanka", label: "Fibabanka" },
    { value: "Şekerbank", label: "Şekerbank" },
    { value: "TurkishBank", label: "TurkishBank" },
    { value: "Türk Ticaret Bankası", label: "Türk Ticaret Bankası" },
    { value: "Türkiye İş Bankası", label: "Türkiye İş Bankası" },
    { value: "Yapı Kredi", label: "Yapı Kredi" },
    { value: "Alternatifbank", label: "Alternatifbank" },
    { value: "Burgan Bank", label: "Burgan Bank" },
    { value: "DenizBank", label: "DenizBank" },
    { value: "Garanti BBVA", label: "Garanti BBVA" },
    { value: "HSBC Türkiye", label: "HSBC Türkiye" },
    { value: "ING Group", label: "ING Group" },
    { value: "Odeabank", label: "Odeabank" },
    { value: "QNB Finansbank", label: "QNB Finansbank" },
    { value: "Türk Ekonomi Bankası", label: "Türk Ekonomi Bankası" },
    {
      value: "Türkiye Emlak Katılım Bankası",
      label: "Türkiye Emlak Katılım Bankası",
    },
    { value: "Vakıf Katılım Bankası", label: "Vakıf Katılım Bankası" },
    { value: "Ziraat Katılım", label: "Ziraat Katılım" },
    {
      value: "Albaraka Türk Katılım Bankası",
      label: "Albaraka Türk Katılım Bankası",
    },
    { value: "Kuveyt Türk", label: "Kuveyt Türk" },
    { value: "Türkiye Finans", label: "Türkiye Finans" },
  ];

  const [bank, setBank] = useState(null);
  return (
    <Modal>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="lg:md:p-8 p-4 w-[90vw] rounded-2xl bg-white lg:md:min-w-[480px] md:max-w-lg "
      >
        <div className="flex flex-col max-h-[80vh] p-1 overflow-y-auto">
          <div className="flex items-center justify-between">
            <p className="break-all lg:md:text-3xl text-xl font-semibold">
              Ödemeyi doğrula
            </p>
            <RxCross1
              className="text-2xl cursor-pointer"
              onClick={() => setPaymentModal(!isEdit ? false : null)}
            />
          </div>
        </div>
        <div className="flex flex-col space-y-2 my-4">
          <div className="flex items-center justify-between gap-x-2">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
              <label htmlFor="first_name" className="col-span-full font-medium">
                Ad
              </label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                {...register("first_name", { required: true })}
                className="col-span-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                placeholder="örn. Alex"
              />
              {errors.first_name && (
                <span className="w-full text-red-500 -mt-1 cursor-context-menu">
                  Bu alan gereklidir
                </span>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
              <label htmlFor="last_name" className="col-span-full font-medium">
                Soyad
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                {...register("last_name", { required: true })}
                className="col-span-full px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                placeholder="örn. John"
              />
              {errors.last_name && (
                <span className="w-full text-red-500 -mt-1 cursor-context-menu">
                  Bu alan gereklidir
                </span>
              )}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
            <label htmlFor="bank_name" className="col-span-full font-medium ">
              Banka Adı
            </label>
            <div className="col-span-full w-full">
              <Select
                value={bank}
                onChange={(e) => setBank(e)}
                options={banks}
                isSearchable
                loading={loading}
                primaryColor={"lime"}
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none w-full ${
                      isDisabled
                        ? "bg-gray-100"
                        : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 text-gray-700 max-h-96 overflow-y-auto",
                  searchIcon:
                    "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                  searchBox:
                    "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate ${
                      isSelected
                        ? "text-white bg-primary"
                        : "text-black hover:bg-green-100 hover:text-primary"
                    }`,
                }}
                placeholder="Banka Seçiniz"
              />
            </div>
          </div>

          {errors.bank_name && (
            <span className="w-full text-red-500 -mt-1 cursor-context-menu">
              Bu alan gereklidir
            </span>
          )}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-2 w-full">
            <label htmlFor="iban_number" className="col-span-full font-medium">
              IBAN Numarası
            </label>
            <div className="col-span-full px-3 py-2 border rounded-md flex items-center gap-x-2">
              <p>TR</p>
              <input
                type="text"
                id="iban_number"
                name="iban_number"
                {...register("iban_number", {
                  required: "Bu alan gereklidir",
                  pattern: {
                    value: /^\d{24}$/,
                    message: "IBAN numarası 24 haneli olmak zorundadır",
                  },
                })}
                className="w-full p-1 rounded-lg focus:outline-none focus:ring-1 focus:ring-primary"
                placeholder="IBAN Numarasını Girin"
              />
            </div>
          </div>
          {errors.iban_number && (
            <span className="w-full text-red-500 -mt-1 cursor-context-menu">
              {errors.iban_number.message}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between space-x-2 pt-3 border-t">
          <button
            type="button"
            className="px-4 py-3 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none w-full"
            onClick={() => setPaymentModal(!isEdit ? false : null)}
          >
            İptal
          </button>
          <button
            type="submit"
            className={`px-4 font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-90 transition-all focus:outline-none w-full ${
              loading ? "cursor-not-allowed py-[14px] opacity-50" : "py-[12px]"
            }`}
            disabled={loading}
          >
            {loading ? (
              <div className="loaderProfile mx-auto "></div>
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OtpModal;
