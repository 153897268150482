import React, { useState } from "react";
import { showProducts } from "../../constants/data";
import { FaRegEye } from "react-icons/fa";

export const Projects = () => {
  const [select, setSelect] = useState(null);

  return (
    <div className="eye-section hidden lg:md:block ">
      <p
        className="font-semibold text-3xl text-center w-full pb-6 mb-12 "
        style={{
          color: "#03446a",
        }}
      >
        KOCFREELANCING'TE YAPILAN İLHAM VERİCİ ÇALIŞMALAR
      </p>
      <ul className="eye-card !px-4">
        {showProducts.map((element, idx) => {
          return (
            <li
              key={`showproducts-${idx}`}
              className={`card-item ${select === idx && "expand"}`}
            >
              <img src={element} alt="" />
              <span
                onClick={() => {
                  if (select === idx) {
                    setSelect(null);
                  } else {
                    setSelect(idx);
                  }
                }}
              >
                <FaRegEye className="w-6 h-6" />
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
