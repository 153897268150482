import Logout from "@mui/icons-material/Logout";
import Settings from "@mui/icons-material/Settings";
import Contact from "@mui/icons-material/ContactSupport";
import Avatar from "@mui/material/Avatar";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PaidIcon from "@mui/icons-material/Paid";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import {
  getNotifications,
  getProfile,
  getProfileById,
  patchReadNotifications,
} from "../../axios/axios";
import { formatDistance } from "date-fns";
import { tr } from "date-fns/locale";
import { Badge } from "@mui/material";
import { MdAccountCircle, MdRefresh } from "react-icons/md";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import io from "socket.io-client";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountMenu = ({ setShowMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.data);
  const userLoading = useSelector((state) => state.user.loading);
  const percentage = useSelector((state) => state.user.completionPercentage);
  const [userData, setUserData] = useState(null);

  const socketRef = useRef(null); // Use ref to store socket instance
  const toastShownRef = useRef(false); // Ref to track if toast is shown

  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleToggle = async () => {
    setShowMenu(false);
    handleRefresh();

    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [open1, setOpen1] = useState(false);
  const anchorRef1 = useRef(null);
  const handleToggle1 = () => {
    setShowMenu(false);
    setOpen1((prevOpen1) => !prevOpen1);
    handleRefresh();
  };

  const handleClose1 = (event) => {
    if (anchorRef1.current && anchorRef1.current.contains(event.target)) {
      return;
    }
    setOpen1(false);
  };

  const [open2, setOpen2] = useState(false);
  const anchorRef2 = useRef(null);
  const handleToggle2 = () => {
    setShowMenu(false);
    setOpen2((prevOpen2) => !prevOpen2);
    handleRefresh();
  };

  const handleClose2 = (event) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpen2(false);
  };

  const handleProfile = (e) => {
    navigate(`/profile/me`, { scroll: false });
    handleClose(e);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  function handleListKeyDown1(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef.current) {
        anchorRef.current.focus();
      }
    }
    prevOpen.current = open;
  }, [open]);

  const prevOpen1 = useRef(open);
  useEffect(() => {
    if (prevOpen1.current === true && open === false) {
      if (anchorRef1.current) {
        anchorRef1.current.focus();
      }
    }
    prevOpen1.current = open;
  }, [open]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await dispatch(getNotifications(userProfile?.user?._id));
      setNotifications(response?.payload?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    await fetchData();
    await dispatch(getProfile());
    dispatch(patchReadNotifications(userProfile?.user?._id));
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, userProfile?.user?._id, navigate]);

  useEffect(() => {
    const userId = userProfile?.user?._id;

    if (userId) {
      if (!socketRef.current) {
        console.log("Initializing socket..."); // Debug log
        socketRef.current = io(`https://api.kocfreelancing.com`, {
          query: { userId },
        });

        // Define a notification handler function
        const handleNotification = (data) => {
          // Use ref to track if the toast is shown
          if (!toastShownRef.current) {
            toast.success(`${data.text}`, {
              position: "bottom-left",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              pauseOnFocusLoss: true,
              transition: Bounce,
              onClick: () => {
                if (data.url) {
                  window.location.href = data.url;
                }
              },
            });
            toastShownRef.current = true; // Mark toast as shown

            // Reset toastShownRef after the toast duration
            setTimeout(() => {
              toastShownRef.current = false; // Allow future toasts
            }, 8000);
          }
        };

        // Attach the listener only once
        socketRef.current.on("new_notification", handleNotification);

        return () => {
          console.log("Cleaning up socket..."); // Debug log
          socketRef.current.off("new_notification", handleNotification); // Remove specific listener
          socketRef.current.disconnect();
          socketRef.current = null;
        };
      }
    }

    // Cleanup to handle component unmount or user change
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [userProfile?.user?._id]); // Effect dependencies

  // useEffect(() => {
  //   if (!socketRef.current && userProfile?.user?._id) {
  //     socketRef.current = io(`https://api.kocfreelancing.com`, {
  //       query: { userId: userProfile.user._id },
  //     });

  //     socketRef.current.on("new_notification", (data) => {
  //       if (!toastShownRef.current) {
  //         toast.success(`${data.text}`, {
  //           position: "bottom-left",
  //           autoClose: 8000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "light",
  //           pauseOnFocusLoss: true,
  //           transition: Bounce,
  //           onClick: () => {
  //             if (data.url) {
  //               window.location.href = data.url;
  //             }
  //           },
  //         });
  //         toastShownRef.current = true; // Mark toast as shown
  //       }
  //     });

  //     return () => {
  //       socketRef.current.disconnect();
  //       socketRef.current = null;
  //     };
  //   }
  // }, [userProfile?.user?._id]);

  const formatBalance = (balance) => {
    if (balance >= 1_000_000_000) {
      return (balance / 1_000_000_000).toFixed(1) + "B";
    } else if (balance >= 1_000_000) {
      return (balance / 1_000_000).toFixed(1) + "M";
    } else if (balance >= 1_000) {
      return (balance / 1_000).toFixed(1) + "k";
    } else {
      return balance.toFixed(2);
    }
  };

  return (
    <Stack direction="row" className="lg:md:ml-0 ml-[-10px] mt-0">
      <div className="lg:md:mx-0 mx-[-5px] lg:md:scale-100 scale-[.84] z-[10]">
        <IconButton
          ref={anchorRef1}
          id="account-button"
          aria-controls={open1 ? "account-menu" : undefined}
          aria-expanded={open1 ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle1}
        >
          <Badge
            badgeContent={notifications?.filter((item) => !item.read).length}
            color="success"
          >
            <CircleNotificationsIcon
              sx={{
                width: 32,
                height: 32,
                color: "#404145",
              }}
            />
          </Badge>
        </IconButton>
        <Popper
          open={open1}
          anchorEl={anchorRef1.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose1}>
                  <MenuList
                    autoFocusItem={open1}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown1}
                    className="lg:md:w-[380px] w-[80vw]  bg-gray-50 rounded-3xl "
                  >
                    <div className="bg-gray-50 h-[80vh] lg:h-[50vh] w-full overflow-y-scroll !p-3  absolute right-0 !z-[5000000000]">
                      <div className="flex items-center justify-between">
                        <p
                          tabIndex={0}
                          className="focus:outline-none mb-2 text-xl font-semibold leading-6 text-gray-800"
                        >
                          Bildirimler
                        </p>
                        <MdRefresh
                          className="w-6 h-6 cursor-pointer"
                          onClick={handleRefresh}
                        />
                      </div>
                      {!loading ? (
                        notifications?.map((item, index) => (
                          <div
                            className={`bg-gray-50 w-full hover:bg-gray-100 hover:rounded-lg cursor-pointer !p-2  overflow-x-hidden flex items-start gap-x-2 ${
                              !notifications?.length - 1 === index && "border-b"
                            }`}
                            style={{ padding: ".5rem" }}
                            key={index}
                            onClick={() =>
                              item.url && (window.location.href = item.url)
                            }
                          >
                            <Avatar
                              sx={{
                                width: 40,
                                height: 40,
                                backgroundColor: "#404145",
                                color: "white",
                                fontSize: 16,
                                borderRadius: 8,
                                marginTop: 0.5,
                              }}
                              src={item?.img}
                            >
                              KOC
                            </Avatar>
                            <div>
                              <p className="">{item?.text}</p>
                              <p className=" text-sm mt-1 leading-normal font-medium text-secondary">
                                {item?.createdAt &&
                                  (({ timestamp }) => (
                                    <span>
                                      {formatDistance(
                                        new Date(timestamp),
                                        new Date(),
                                        {
                                          addSuffix: true,
                                          locale: tr,
                                        }
                                      )}
                                    </span>
                                  ))({ timestamp: item?.createdAt ?? 0 })}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center col-span-1 lg:md:col-span-3 justify-center h-[30vh] mx-auto z-[4000000]">
                          <div className="loader"></div>
                        </div>
                      )}
                      <div className="flex items-center justify-between">
                        <hr className="w-full" />
                        <p
                          tabIndex={0}
                          className="focus:outline-none text-sm flex flex-shrink-0 leading-normal !px-3 !py-8 text-secondary"
                        >
                          {!loading &&
                            (notifications?.length > 1
                              ? "Şimdilik bu kadar"
                              : "Hiçbir bildirim bulunamadı")}
                        </p>
                        <hr className="w-full" />
                      </div>
                    </div>
                    <MenuItem onClick={() => navigate("/profile/me")}>
                      <ListItemIcon>
                        <MdAccountCircle className="w-6 h-6" />
                      </ListItemIcon>
                      Profil
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/profile/settings")}>
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      Ayarlar
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/profile/withdraw")}>
                      <ListItemIcon>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      Para Çekme
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/profile/add-balance")}>
                      <ListItemIcon>
                        <MoneyIcon />
                      </ListItemIcon>
                      Bakiye Ekle
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/profile/transactions")}>
                      <ListItemIcon>
                        <PaidIcon />
                      </ListItemIcon>
                      İşlem Geçmişi
                    </MenuItem>
                    <MenuItem onClick={() => navigate("/contact")}>
                      <ListItemIcon>
                        <Contact />
                      </ListItemIcon>
                      Bize Ulaşın
                    </MenuItem>
                    <MenuItem onClick={() => setShowConfirmationModal(true)}>
                      <ListItemIcon>
                        <Logout />
                      </ListItemIcon>
                      Çıkış Yap
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      {percentage < 100 && (
        <div className="lg:md:mx-0 -mx-2 xs:-mx-1.5 lg:md:scale-100 scale-90">
          <IconButton
            ref={anchorRef2}
            id="account-button"
            aria-controls={open2 ? "account-menu" : undefined}
            aria-expanded={open2 ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleToggle2}
          >
            <ErrorOutlineIcon
              sx={{
                width: 32,
                height: 32,
                color: "#F87171",
              }}
            />
          </IconButton>
          <Popper
            open={open2}
            anchorEl={anchorRef2.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose2}>
                    <MenuList
                      autoFocusItem={open2}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      className="w-72  bg-gray-50 rounded-3xl"
                    >
                      {!userLoading ? (
                        <MenuItem
                          onClick={(e) => handleProfile(e)}
                          className="flex flex-col "
                        >
                          <div className="w-[140px] h-[140px] rounded-full ">
                            <div className="relative size-[140px]">
                              <svg
                                className="size-full"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="18"
                                  cy="18"
                                  r="16"
                                  fill="none"
                                  className="stroke-current text-primary "
                                  strokeWidth="2"
                                ></circle>
                                <g className="origin-center -rotate-90 transform">
                                  <circle
                                    cx="18"
                                    cy="18"
                                    r="16"
                                    fill="none"
                                    className="stroke-current text-red-300 -500"
                                    strokeWidth="2"
                                    strokeDasharray="100"
                                    strokeDashoffset={percentage}
                                  ></circle>
                                </g>
                              </svg>
                              <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                                <span className="text-center text-xl font-bold text-gray-700 ">
                                  {percentage}%
                                </span>
                              </div>
                            </div>
                          </div>

                          <p className=" text-secondary text-xl my-1 font-medium">
                            Lütfen profilinizi tamamlayın
                          </p>
                        </MenuItem>
                      ) : (
                        <div className="flex items-center col-span-1 lg:md:col-span-3 justify-center h-[30vh] mx-auto">
                          <div className="loader"></div>
                        </div>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      )}

      <div className="lg:md:mx-0 -mx-2 xs:-mx-1.5 lg:md:scale-100 scale-90">
        <IconButton
          ref={anchorRef}
          id="account-button"
          aria-controls={open ? "account-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {userProfile?.user?.profile_picture ? (
            <div className="w-[32px] h-[32px] border rounded-full ">
              <img
                src={userProfile?.user?.profile_picture}
                // width={32}
                // height={32}
                alt="profile picture"
                className="object-cover rounded-full w-full h-full"
              />
            </div>
          ) : (
            <Avatar
              sx={{
                width: 32,
                height: 32,
                backgroundColor: "#404145",
                color: "white",
                fontSize: 16,
              }}
            >
              {(userProfile?.user?.first_name?.slice(0, 1) ?? "") +
                (userProfile?.user?.lastName?.slice(0, 1) ?? "")}
            </Avatar>
          )}
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    className="w-60  bg-gray-50 rounded-3xl max-h-[80vh] overflow-y-auto"
                  >
                    {!userLoading ? (
                      <MenuItem
                        onClick={(e) => handleProfile(e)}
                        onMouseUp={(e) =>
                          e.button === 1 && window.open(`/profile/me`, "_blank")
                        }
                        className="flex flex-col "
                      >
                        {userProfile?.user?.profile_picture ? (
                          <div className="w-[120px] h-[120px] border rounded-full ">
                            <img
                              src={userProfile?.user?.profile_picture}
                              alt="profile picture"
                              className="object-cover rounded-full w-full h-full"
                            />
                          </div>
                        ) : (
                          <Avatar
                            sx={{
                              width: 120,
                              height: 120,
                              backgroundColor: "#404145",
                              color: "white",
                              fontSize: 16,
                            }}
                          >
                            {(userProfile?.user?.first_name?.slice(0, 1) ??
                              "") +
                              (userProfile?.user?.lastName?.slice(0, 1) ?? "")}
                          </Avatar>
                        )}
                        {userProfile?.user && (
                          <>
                            <p className=" text-secondary text-xl my-1 font-medium">
                              {userProfile?.user?.first_name +
                                " " +
                                userProfile?.user?.lastName}
                            </p>
                            <p className=" text-secondary  my-1 font-medium">
                              Toplam Bakiye : ₺
                              {userProfile?.balance.toFixed(2) ?? 0.0}
                            </p>
                          </>
                        )}
                      </MenuItem>
                    ) : (
                      <div className="flex items-center col-span-1 lg:md:col-span-3 justify-center h-[22vh] mx-auto">
                        <div className="loader"></div>
                      </div>
                    )}
                    <MenuItem
                      onClick={() => navigate("/profile/me")}
                      onMouseUp={(e) =>
                        e.button === 1 && window.open(`/profile/me`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <MdAccountCircle className="w-6 h-6" />
                      </ListItemIcon>
                      Profil
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate("/profile/settings")}
                      onMouseUp={(e) =>
                        e.button === 1 &&
                        window.open(`/profile/settings`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <Settings />
                      </ListItemIcon>
                      Ayarlar
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate("/profile/withdraw")}
                      onMouseUp={(e) =>
                        e.button === 1 &&
                        window.open(`/profile/withdraw`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <AccountBalanceIcon />
                      </ListItemIcon>
                      Para Çekme
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate("/profile/add-balance")}
                      onMouseUp={(e) =>
                        e.button === 1 &&
                        window.open(`/profile/add-balance`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <MoneyIcon />
                      </ListItemIcon>
                      Bakiye Ekle
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate("/profile/transactions")}
                      onMouseUp={(e) =>
                        e.button === 1 &&
                        window.open(`("/profile/transactions`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <PaidIcon />
                      </ListItemIcon>
                      İşlem Geçmişi
                    </MenuItem>
                    <MenuItem
                      onClick={() => navigate("/contact")}
                      onMouseUp={(e) =>
                        e.button === 1 && window.open(`/contact`, "_blank")
                      }
                    >
                      <ListItemIcon>
                        <Contact />
                      </ListItemIcon>
                      Bize Ulaşın
                    </MenuItem>
                    <MenuItem onClick={() => setShowConfirmationModal(true)}>
                      <ListItemIcon>
                        <Logout />
                      </ListItemIcon>
                      Çıkış Yap
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showConfirmationModal && (
          <ConfirmationModal
            title="Çıkış yapmak"
            loading={loading || userLoading}
            onClose={() => setShowConfirmationModal(false)}
            onConfirm={() => {
              setLoading(true);
              localStorage.removeItem("accessToken");
              localStorage.removeItem("userId");
              Cookies.remove("refreshToken");
              Cookies.remove("role");
              Cookies.remove("user_id");
              Cookies.remove("userSig");
              setLoading(false);
              navigate("/auth/login");
            }}
          />
        )}
      </AnimatePresence>
    </Stack>
  );
};

export default AccountMenu;
