import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";
import ProjectCard from "../../components/projectCard/projectCard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JobsModal from "../../components/modals/JobsModal";
import { AnimatePresence } from "framer-motion";
import {
  getBookmarks,
  getMyJobs,
  getJobs,
  getAllJobs,
  getCountries,
  getCategories,
  getSubCategoriesById,
} from "../../axios/axios";
import { setLoading } from "../../redux/reducers/loadingSlice";
import ProtectedRoute from "../../components/layouts/ProtectedRoute";
import Select from "react-tailwindcss-select";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const Jobs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeJobs, setActiveJobs] = useState("bestMatches");
  const loading = useSelector((state) => state.loading.loading);
  const userProfile = useSelector((state) => state.user.data);
  const [searchTerm, setSearchTerm] = useState("");
  const allJobs = useSelector((state) => state.jobs?.allJobs?.data);
  const bestMatches = useSelector((state) => state.jobs?.jobs?.data);
  const bookmarks = useSelector((state) => state.jobs?.bookmarks?.data);
  const myJobs = useSelector((state) => state.jobs?.myJobs?.data);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [minBudget, setMinBudget] = useState("");
  const [maxBudget, setMaxBudget] = useState("");
  const [projectSize, setProjectSize] = useState("");
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        // Fetch countries data
        const countriesResponse = await dispatch(getCountries());
        setCountriesOptions(
          countriesResponse?.payload?.data?.map((item) => ({
            value: item.name,
            label: item.name ?? "",
            cities: item.cities,
          }))
        );
        // Fetch categories data
        const categoriesResponse = await dispatch(getCategories(""));
        setCategoriesOptions(
          categoriesResponse?.payload?.data?.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
        if (searchTerm === "") {
          // Fetch sub categories data
          const subCategoriesResponse = await dispatch(
            getSubCategoriesById({ id: userProfile?.category?._id })
          );
          setSubCategoriesOptions(
            subCategoriesResponse?.payload?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        } else {
          console.log("siuuuu", selectedCategory);
          // Fetch sub categories data
          const subCategoriesResponse = await dispatch(
            getSubCategoriesById({ id: selectedCategory?.value })
          );
          setSubCategoriesOptions(
            subCategoriesResponse?.payload?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            }))
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      dispatch(setLoading(false));
    };

    fetchData();
  }, [dispatch, searchTerm, selectedCategory, userProfile]);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      if (searchTerm !== "") {
        await dispatch(
          getAllJobs({
            user_id: userProfile?.user?._id,
            searchTerm: searchTerm,
            sortOrder: sortOrder,
            sortBy: sortBy,
            minBudget: minBudget,
            maxBudget: maxBudget,
            project_size: projectSize,
            category: selectedCategory === "" ? "" : selectedCategory.value,
            subCategory:
              !selectedSubCategory || selectedSubCategory?.length === 0
                ? ""
                : selectedSubCategory.map((item) => item.value).join(","),
            country: selectedCountry === "" ? "" : selectedCountry.value,
          })
        );
      } else {
        await dispatch(
          getJobs({
            user_id: userProfile?.user?._id,
            sortOrder: sortOrder,
            sortBy: sortBy,
            minBudget: minBudget,
            maxBudget: maxBudget,
            project_size: projectSize,
            country: selectedCountry === "" ? "" : selectedCountry.value,
            subCategory:
              !selectedSubCategory || selectedSubCategory?.length === 0
                ? ""
                : selectedSubCategory.map((item) => item.value).join(","),
          })
        );
        await dispatch(getBookmarks(userProfile?.user?._id));
        await dispatch(getMyJobs(userProfile?.user?._id));
      }
      dispatch(setLoading(false));
    };
    fetchData();
  }, [
    dispatch,
    maxBudget,
    minBudget,
    projectSize,
    searchTerm,
    selectedCategory,
    selectedCountry,
    selectedSubCategory,
    sortBy,
    sortOrder,
    userProfile,
  ]);

  console.log("jobs", bestMatches, allJobs, loading);

  const [displayedBestMatches, setDisplayedBestMatches] = useState(10);
  const [displayedMyJobs, setDisplayedMyJobs] = useState(10);
  const [displayedBookmarks, setDisplayedBookmarks] = useState(10);
  const [displayedAllJobs, setDisplayedAllJobs] = useState(10);
  const loadMoreJobs = () => {
    if (searchTerm !== "") {
      setDisplayedAllJobs((prev) => prev + 10);
    } else if (activeJobs === "bestMatches") {
      setDisplayedBestMatches((prev) => prev + 10);
    } else if (activeJobs === "myJobs") {
      setDisplayedMyJobs((prev) => prev + 10);
    } else if (activeJobs === "bookmarks") {
      setDisplayedBookmarks((prev) => prev + 10);
    }
  };

  const showLessJobs = () => {
    if (searchTerm !== "") {
      setDisplayedAllJobs(10);
    } else if (activeJobs === "bestMatches") {
      setDisplayedBestMatches(10);
    } else if (activeJobs === "myJobs") {
      setDisplayedMyJobs(10);
    } else if (activeJobs === "bookmarks") {
      setDisplayedBookmarks(10);
    }
  };

  const [showAddJobModal, setShowAddJobModal] = useState(false);
  return (
    <ProtectedRoute>
      <Navbar />
      <div className=" max-w-[1400px]  lg:px-4   my-28 mx-4 lg:md:mx-auto flex flex-col lg:md:grid grid-cols-4 gap-x-6">
        <div className="col-span-3">
          <div className="relative flex items-center w-full border h-12 rounded-3xl  bg-white overflow-hidden">
            <div className="grid place-items-center h-full w-12 text-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </div>
            <input
              className="peer h-full w-full outline-none text-sm text-secondary pr-2"
              type="text"
              id="search"
              placeholder="Ara"
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
            />
          </div>
          <button
            className="block lg:md:hidden rounded-3xl my-3  w-full py-3 bg-primary hover:bg-opacity-90 transition-all border text-white text-center active:scale-95 "
            onClick={() => {
              if (
                userProfile?.description &&
                userProfile?.hourly_rate &&
                userProfile?.sub_title
              ) {
                setShowAddJobModal(true);
              } else {
                toast.error("Lütfen önce profilinizi tamamlayın.");
                navigate("/profile/me");
              }
            }}
          >
            Yeni bir iş ilanı ver
          </button>
          <div className="flex items-center justify-between mt-4 lg:md:my-8 ">
            <p className="break-all font-medium text-lg lg:md:text-2xl">
              Önerilen işler
            </p>
            {!(activeJobs === "myJobs" || activeJobs === "bookmarks") && (
              <div className="lg:md:block flex flex-col justify-end ">
                {" "}
                <label
                  htmlFor="sort"
                  className="lg:md:mx-2 lg:md:text-sm mx-1 font-medium"
                >
                  Sırala
                </label>
                <select
                  id="sort"
                  className="cursor-pointer px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-32 lg:md:w-48"
                  onChange={(event) => {
                    const selectedOption = event.target.value;
                    if (selectedOption === "Newest") {
                      setSortBy("createdAt");
                      setSortOrder("desc");
                    } else if (selectedOption === "Oldest") {
                      setSortBy("createdAt");
                      setSortOrder("asc");
                    } else if (selectedOption === "Lowest Bids") {
                      setSortBy("proposals");
                      setSortOrder("asc");
                    } else if (selectedOption === "Highest Bids") {
                      setSortBy("proposals");
                      setSortOrder("desc");
                    } else if (selectedOption === "Lowest Price") {
                      setSortBy("budget");
                      setSortOrder("asc");
                    } else if (selectedOption === "Highest Price") {
                      setSortBy("budget");
                      setSortOrder("desc");
                    }
                  }}
                >
                  <option value="Newest">En yeni </option>
                  <option value="Oldest">En eski</option>
                  <option value="Lowest Bids">En az teklif verilen</option>
                  <option value="Highest Bids">En çok teklif verilen</option>
                  <option value="Lowest Price">En düşük fiyat</option>
                  <option value="Highest Price">En yüksek fiyat</option>
                </select>
              </div>
            )}
          </div>
          <div className="lg:md:hidden mb-4 col-span-1">
            <p className="break-all lg:md:my-8 mt-4 mx-2 lg:md:mx-0 font-medium text-lg lg:md:text-2xl">
              Filtreler
            </p>
            <div className=" w-full  overflow-y-scroll no-scrollbar border  rounded-3xl bg-[#ffffff] overflow-hidden py-3 px-3">
              <div className="my-2 ">
                <div className="flex items-center justify-between">
                  <p className="break-all font-medium !mb-2">İş Bütçesi (₺)</p>
                  <p
                    className=" text-primary cursor-pointer"
                    onClick={() => {
                      setMinBudget("");
                      setMaxBudget("");
                    }}
                  >
                    Temizle
                  </p>
                </div>
                <div className="flex items-center justify-between gap-3 mt-1">
                  <div className="w-1/2">
                    <label htmlFor="minBudget" className="font-medium">
                      En az
                    </label>
                    <input
                      type="number"
                      id="minBudget"
                      name="minBudget"
                      className="p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full"
                      placeholder="Örn: 20"
                      onChange={(e) => setMinBudget(e.target.value)}
                      value={minBudget}
                    />
                  </div>
                  <div className="w-1/2">
                    <label htmlFor="maxBudget" className="font-medium">
                      En çok
                    </label>
                    <input
                      type="number"
                      id="maxBudget"
                      name="maxBudget"
                      className="p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full"
                      placeholder="Örn: 200"
                      onChange={(e) => setMaxBudget(e.target.value)}
                      value={maxBudget}
                    />
                  </div>
                </div>
              </div>
              <div className="py-2">
                <div className="flex items-center justify-between mb-1">
                  <p className="break-all font-medium">Proje Boyutu</p>
                  <p
                    className=" text-primary cursor-pointer"
                    onClick={() => setProjectSize("")}
                  >
                    Temizle
                  </p>
                </div>
                <select
                  id="size"
                  name="size"
                  className="cursor-pointer px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full "
                  onChange={(e) => setProjectSize(e.target.value)}
                  value={projectSize}
                >
                  <option value="">Proje boyutu seçiniz</option>
                  <option value="Küçük">Küçük</option>
                  <option value="Orta">Orta</option>
                  <option value="Büyük">Büyük</option>
                </select>
              </div>
              {searchTerm !== "" && (
                <div className="py-2">
                  <div className="flex items-center justify-between mb-1">
                    <p className="break-all font-medium">Kategori</p>
                    <p
                      className=" text-primary cursor-pointer"
                      onClick={() => setSelectedCategory("")}
                    >
                      Temizle
                    </p>
                  </div>
                  <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e)}
                    options={categoriesOptions}
                    isSearchable
                    loading={loading}
                    primaryColor={"lime"}
                    placeholder="Kategori seçin"
                    classNames={{
                      searchIcon:
                        "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                      searchBox:
                        "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                      menuButton: ({ isDisabled }) =>
                        `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none ${
                          isDisabled
                            ? "bg-gray-100"
                            : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                        }`,
                      menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 rounded-lg text-gray-700",
                      listItem: ({ isSelected }) =>
                        `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate rounded ${
                          isSelected
                            ? `text-white bg-primary`
                            : `text-black hover:bg-green-100 hover:text-primary`
                        }`,
                    }}
                  />
                </div>
              )}
              <div className="py-2">
                <div className="flex items-center justify-between mb-1">
                  <p className="break-all font-medium">Alt kategori </p>
                  <p
                    className=" text-primary cursor-pointer"
                    onClick={() => setSelectedSubCategory([])}
                  >
                    Temizle
                  </p>
                </div>
                <Select
                  value={selectedSubCategory}
                  onChange={(e) => setSelectedSubCategory(e)}
                  options={subCategoriesOptions}
                  isSearchable
                  isMultiple
                  loading={loading}
                  primaryColor={"lime"}
                  placeholder="Seçin Alt kategori"
                  classNames={{
                    searchIcon:
                      "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                    searchBox:
                      "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                    menuButton: ({ isDisabled }) =>
                      `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none !py-2 ${
                        isDisabled
                          ? "bg-gray-100"
                          : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                      }`,
                    menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 rounded-lg text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-primary`
                          : `text-black hover:bg-green-100 hover:text-primary`
                      }`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="border rounded-3xl max-w-[1400px]    justify-center    lg:md:mb-14 mx-auto">
            {searchTerm !== "" &&
              (loading ? (
                <div className="rounded-3xl max-w-[1400px]  lg:px-4  flex items-center justify-center h-full lg:md:h-[80vh]  lg:md:mb-14 mx-auto">
                  <div className="loader"></div>
                </div>
              ) : allJobs?.length > 0 ? (
                [...allJobs]
                  .slice(0, displayedAllJobs)
                  .map((item, index) => (
                    <ProjectCard
                      data={item}
                      key={index}
                      length={allJobs?.length}
                      index={index}
                      myJob={false}
                      userProfile={userProfile}
                    />
                  ))
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src="/assets/404.png"
                    width={400}
                    height={400}
                    alt="Veri bulunamadı"
                  />
                </div>
              ))}
            {searchTerm === "" && (
              <div>
                <ul className="text-secondary font-medium text-center rounded-t-3xl shadow flex">
                  <li className="flex-1">
                    <button
                      className={`flex items-center justify-center w-full p-4 text-xs lg:text-medium border-r border-gray-200 rounded-tl-3xl ${
                        activeJobs === "bestMatches"
                          ? "text-gray-900 bg-gray-100 h-full"
                          : "bg-white hover:text-gray-700 hover:bg-gray-50 h-full"
                      }`}
                      onClick={() => setActiveJobs("bestMatches")}
                    >
                      En iyi eşleşmeler (
                      {loading ? "..." : bestMatches?.length ?? 0})
                    </button>
                  </li>
                  <li className="flex-1">
                    <button
                      className={`flex items-center justify-center w-full p-4 text-xs lg:text-medium border-r border-gray-200 ${
                        activeJobs === "bookmarks"
                          ? "text-gray-900 bg-gray-100 h-full"
                          : "bg-white hover:text-gray-700 hover:bg-gray-50 h-full"
                      }`}
                      onClick={() => setActiveJobs("bookmarks")}
                    >
                      Yer imleri ({loading ? "..." : bookmarks?.length ?? 0})
                    </button>
                  </li>
                  <li className="flex-1">
                    <button
                      className={`flex items-center justify-center w-full p-4 text-xs lg:text-medium border-gray-200 rounded-tr-3xl ${
                        activeJobs === "myJobs"
                          ? "text-gray-900 bg-gray-100 h-full"
                          : "bg-white hover:text-gray-700 hover:bg-gray-50 h-full"
                      }`}
                      onClick={() => setActiveJobs("myJobs")}
                    >
                      İşleriniz ({loading ? "..." : myJobs?.length ?? 0})
                    </button>
                  </li>
                </ul>
              </div>
            )}

            {loading ? (
              <div className="rounded-3xl max-w-[1400px]  lg:px-4  flex items-center justify-center h-[80vh]  mb-14 mx-auto">
                <div className="loader"></div>
              </div>
            ) : searchTerm === "" && activeJobs === "bestMatches" ? (
              bestMatches?.length > 0 ? (
                bestMatches
                  .slice(0, displayedBestMatches)
                  .map((item, index) => (
                    <ProjectCard
                      data={item}
                      key={index}
                      length={bestMatches?.length}
                      index={index}
                      myJob={false}
                      userProfile={userProfile}
                    />
                  ))
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src="/assets/404.png"
                    width={400}
                    height={400}
                    alt="Veri bulunamadı"
                  />
                </div>
              )
            ) : searchTerm === "" && activeJobs === "bookmarks" ? (
              bookmarks?.length > 0 ? (
                [...bookmarks]
                  .reverse()
                  .slice(0, displayedBookmarks)
                  .map((item, index) => (
                    <ProjectCard
                      data={item}
                      key={index}
                      length={bookmarks?.length}
                      index={index}
                      myJob={false}
                      isBookmark={true}
                      userProfile={userProfile}
                    />
                  ))
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src="/assets/404.png"
                    width={400}
                    height={400}
                    alt="Veri bulunamadı"
                  />
                </div>
              )
            ) : searchTerm === "" && activeJobs === "myJobs" ? (
              myJobs?.length > 0 ? (
                [...myJobs]
                  .reverse()
                  .slice(0, displayedMyJobs)
                  .map((item, index) => (
                    <ProjectCard
                      data={item}
                      key={index}
                      length={myJobs?.length}
                      index={index}
                      myJob={true}
                      userProfile={userProfile}
                    />
                  ))
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src="/assets/404.png"
                    width={400}
                    height={400}
                    alt="Veri bulunamadı"
                  />
                </div>
              )
            ) : (
              ""
            )}
            <div
              className={`${
                loading ? "hidden" : "flex"
              } items-center justify-center gap-x-2 `}
            >
              {searchTerm !== "" && allJobs?.length > displayedAllJobs && (
                <div className="flex items-center justify-center my-4">
                  <button
                    className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                    onClick={loadMoreJobs}
                  >
                    Daha fazla yükle jobs
                  </button>
                </div>
              )}

              {searchTerm !== "" && displayedAllJobs > 10 && (
                <div className="flex items-center justify-center my-4">
                  <button
                    className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                    onClick={showLessJobs}
                  >
                    Daha az göster
                  </button>
                </div>
              )}
            </div>
            <div
              className={`${
                loading ? "hidden" : "flex"
              } items-center justify-center gap-x-2 `}
            >
              {searchTerm === "" &&
                activeJobs === "myJobs" &&
                myJobs?.length > displayedMyJobs && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={loadMoreJobs}
                    >
                      Daha fazla yükle jobs
                    </button>
                  </div>
                )}

              {searchTerm === "" &&
                activeJobs === "myJobs" &&
                displayedMyJobs > 10 && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={showLessJobs}
                    >
                      Daha az göster
                    </button>
                  </div>
                )}
            </div>
            <div
              className={`${
                loading ? "hidden" : "flex"
              } items-center justify-center gap-x-2 `}
            >
              {searchTerm === "" &&
                activeJobs === "bookmarks" &&
                bookmarks?.length > displayedBookmarks && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={loadMoreJobs}
                    >
                      Daha fazla yükle
                    </button>
                  </div>
                )}
              {searchTerm === "" &&
                activeJobs === "bookmarks" &&
                displayedBookmarks > 10 && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={showLessJobs}
                    >
                      Daha az göster
                    </button>
                  </div>
                )}
            </div>
            <div
              className={`${
                loading ? "hidden" : "flex"
              } items-center justify-center gap-x-2 `}
            >
              {searchTerm === "" &&
                activeJobs === "bestMatches" &&
                bestMatches?.length > displayedBestMatches && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={loadMoreJobs}
                    >
                      Daha fazla yükle
                    </button>
                  </div>
                )}
              {searchTerm === "" &&
                activeJobs === "bestMatches" &&
                displayedBestMatches > 10 && (
                  <div className="flex items-center justify-center my-4">
                    <button
                      className="rounded-3xl px-3 py-1 border-primary border text-primary text-center active:scale-95"
                      onClick={showLessJobs}
                    >
                      Daha az göster
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="lg:md:block hidden col-span-1">
          <button
            className=" rounded-3xl  w-full py-3 bg-primary hover:bg-opacity-90 transition-all border text-white text-center active:scale-95 "
            onClick={() => {
              if (
                userProfile?.description &&
                userProfile?.hourly_rate &&
                userProfile?.sub_title
              ) {
                setShowAddJobModal(true);
              } else {
                toast.error("Lütfen önce profilinizi tamamlayın.");
                navigate("/profile/me");
              }
            }}
          >
            Yeni Bir İş İlanı Ver
          </button>
          <p className="break-all my-8 font-medium  text-2xl">Filtreler</p>
          <div className=" w-full min-h-[70vh] overflow-y-scroll no-scrollbar border mt-6 rounded-3xl bg-[#ffffff] overflow-hidden py-3 px-6">
            <div className="my-2">
              <div className="flex items-center justify-between mb-1">
                <p className="break-all font-medium">İş Bütçesi (₺)</p>
                <p
                  className=" text-primary cursor-pointer"
                  onClick={() => {
                    setMinBudget("");
                    setMaxBudget("");
                  }}
                >
                  Temizle
                </p>
              </div>
              <div className="flex items-center justify-between gap-3">
                <div className="w-1/2">
                  <label htmlFor="minBudget" className="font-medium">
                    En az
                  </label>
                  <input
                    type="number"
                    id="minBudget"
                    name="minBudget"
                    className="p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full"
                    placeholder="örn. 20"
                    onChange={(e) => setMinBudget(e.target.value)}
                    value={minBudget}
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="maxBudget" className="font-medium">
                    En çok
                  </label>
                  <input
                    type="number"
                    id="maxBudget"
                    name="maxBudget"
                    className="p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full"
                    placeholder="örn. 200"
                    onChange={(e) => setMaxBudget(e.target.value)}
                    value={maxBudget}
                  />
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex items-center justify-between mb-1">
                <p className="break-all font-medium">Proje Boyutu</p>
                <p
                  className=" text-primary cursor-pointer"
                  onClick={() => setProjectSize("")}
                >
                  Temizle
                </p>
              </div>
              <select
                id="size"
                name="size"
                className="cursor-pointer px-3 py-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-primary w-full "
                onChange={(e) => setProjectSize(e.target.value)}
                value={projectSize}
              >
                <option value="">Proje boyutu seçiniz</option>
                <option value="Küçük">Küçük</option>
                <option value="Orta">Orta</option>
                <option value="Büyük">Büyük</option>
              </select>
            </div>
            {searchTerm !== "" && (
              <div className="py-2">
                <div className="flex items-center justify-between mb-1">
                  <p className="break-all font-medium">Kategori</p>
                  <p
                    className=" text-primary cursor-pointer"
                    onClick={() => setSelectedCategory("")}
                  >
                    Temizle
                  </p>
                </div>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e)}
                  options={categoriesOptions}
                  isSearchable
                  loading={loading}
                  primaryColor={"lime"}
                  placeholder="Kategori seçin"
                  classNames={{
                    searchIcon:
                      "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                    searchBox:
                      "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                    menuButton: ({ isDisabled }) =>
                      `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none ${
                        isDisabled
                          ? "bg-gray-100"
                          : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                      }`,
                    menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 rounded-lg text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-primary`
                          : `text-black hover:bg-green-100 hover:text-primary`
                      }`,
                  }}
                />
              </div>
            )}
            <div className="py-2">
              <div className="flex items-center justify-between mb-1">
                <p className="break-all font-medium">Alt kategori</p>
                <p
                  className=" text-primary cursor-pointer"
                  onClick={() => setSelectedSubCategory([])}
                >
                  Temizle
                </p>
              </div>
              <Select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e)}
                options={subCategoriesOptions}
                isSearchable
                isMultiple
                loading={loading}
                primaryColor={"lime"}
                placeholder="Alt kategori"
                classNames={{
                  searchIcon:
                    "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                  searchBox:
                    "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                  menuButton: ({ isDisabled }) =>
                    `flex rounded-lg text-black border border-gray-300 px-3 py-[2px] shadow-sm transition-all duration-300 focus:outline-none ${
                      isDisabled
                        ? "bg-gray-100"
                        : "bg-white hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border rounded p-2 mt-1.5 rounded-lg text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 p-2 rounded-lg cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-primary`
                        : `text-black hover:bg-green-100 hover:text-primary`
                    }`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showAddJobModal && (
          <JobsModal
            setShowJobsModal={setShowAddJobModal}
            showJobsModal={showAddJobModal}
            userProfile={userProfile}
          />
        )}
      </AnimatePresence>
      <Footer />
    </ProtectedRoute>
  );
};

export default Jobs;
