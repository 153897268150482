import { RxCross1 } from "react-icons/rx";
import { MdMenu } from "react-icons/md";
import React from "react";
import { useEffect, useState } from "react";
import AccountMenu from "./menu";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <div className="overflow-x-hidden">
      {/* for mobile */}
      <div className="fixed top-0 left-0 z-50 w-full mx-auto lg:md:hidden block bg-[whitesmoke] !px-3 !py-5 ">
        <div className="flex items-center justify-between w-full bg-[whitesmoke]">
          <Link to="/" className="logo">
            <img src="/logo.png" width={140} height={10} alt="Logo" />
          </Link>
          <div className="flex items-center gap-x-2 bg-[whitesmoke]">
            {isAuthenticated && <AccountMenu setShowMenu={setShowMenu} />}
            {!showMenu ? (
              <MdMenu
                className="w-7 h-7 text-black"
                onClick={() => setShowMenu(!showMenu)}
              />
            ) : (
              <RxCross1
                className="w-7 h-7 text-black"
                onClick={() => setShowMenu(!showMenu)}
              />
            )}
          </div>
        </div>

        {showMenu && (
          <div
            className="bg-[whitesmoke] w-full !p-2 rounded"
            style={{ padding: ".5rem" }}
          >
            {isAuthenticated ? (
              <div className="flex flex-col gap-2 ">
                <Link
                  to="/jobs"
                  className={`${
                    pathname === "/jobs" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  İş İlanları
                </Link>
                <Link
                  to="/categories"
                  className={`${
                    pathname === "/categories" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  Kategoriler
                </Link>
                <Link
                  to="/messages"
                  className={`${
                    pathname === "/messages" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  Mesajlar
                </Link>
                <Link
                  to="/myhires"
                  className={`${
                    pathname === "/myhires" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  Çalışanlarım
                </Link>
                <Link
                  to="/myclients"
                  className={`${
                    pathname === "/myclients" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  Müşterilerim
                </Link>
                <Link
                  to="/help"
                  className={`${
                    pathname === "/help" && "!text-[#e23762]"
                  }  hover:underline text-black border-b py-2 border-white`}
                >
                  Yardım & Destek
                </Link>
              </div>
            ) : (
              <div className="flex flex-col gap-2 bg-[whitesmoke]">
                <Link
                  to="/jobs"
                  className={`${
                    pathname === "/jobs" && "!text-[#e23762]"
                  }  text-black  border-b py-2 border-white`}
                >
                  İş İlanları
                </Link>
                <Link
                  to="/categories"
                  className={`${
                    pathname === "/categories" && "!text-[#e23762]"
                  }  text-black  border-b py-2 border-white`}
                >
                  Kategoriler
                </Link>
                <Link
                  to="/help"
                  className={`${
                    pathname === "/help" && "!text-[#e23762]"
                  }  text-black  border-b py-2 border-white`}
                >
                  Yardım & Destek
                </Link>
              </div>
            )}
            {!isAuthenticated && (
              <div className="flex items-center  bg-[whitesmoke] !my-3 ">
                <Link
                  to="/auth/login"
                  className="login-btn hover:text-[#35B900]"
                >
                  Giriş
                </Link>
                <Link
                  to="/auth/signup"
                  className="signup-btn hover:text-[#fff]"
                >
                  Kayıt
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
      {/* for pc  */}
      <div className="fixed top-0 left-0 z-50 w-[100vw] hidden lg:md:flex items-center justify-center bg-[whitesmoke] !px-8 !py-5">
        <div className="w-[1400px] flex items-center justify-between ">
          <div className="flex items-center ">
            <Link to="/" className="logo lg:md:md:text-2xl text-lg">
              <img src="/logo.png" width={200} height={10} alt="Logo" />
            </Link>
          </div>
          <div className="flex items-center justify-center gap-x-4">
            <div>
              {" "}
              {isAuthenticated ? (
                <div className="navlist">
                  <Link
                    to="/jobs"
                    className={`${
                      pathname === "/jobs" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    İş İlanları
                  </Link>
                  <Link
                    to="/categories"
                    className={`${
                      pathname === "/categories" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Kategoriler
                  </Link>
                  <Link
                    to="/messages"
                    className={`${
                      pathname === "/messages" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Mesajlar
                  </Link>
                  <Link
                    to="/myhires"
                    className={`${
                      pathname === "/myhires" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Çalışanlarım
                  </Link>
                  <Link
                    to="/myclients"
                    className={`${
                      pathname === "/myclients" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Müşterilerim
                  </Link>
                  <Link
                    to="/help"
                    className={`${
                      pathname === "/help" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Yardım & Destek
                  </Link>
                </div>
              ) : (
                <div className="navlist">
                  <Link
                    to="/jobs"
                    className={`${
                      pathname === "/jobs" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    İş İlanları
                  </Link>
                  <Link
                    to="/categories"
                    className={`${
                      pathname === "/categories" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Kategoriler
                  </Link>
                  <Link
                    to="/help"
                    className={`${
                      pathname === "/help" && "!text-[#e23762]"
                    } nav-text hover:text-[#e23762] text-black border-b py-2 px-1 hover:!border-b-[#e23762] border-b-black`}
                  >
                    Yardım & Destek
                  </Link>
                </div>
              )}
            </div>
            <div className="lg:md:md:block hidden">
              {" "}
              {isAuthenticated ? (
                <AccountMenu setShowMenu={setShowMenu} />
              ) : (
                <div className="btn-group">
                  <Link to="/auth/login" className="login-btn ">
                    Giriş
                  </Link>
                  <Link to="/auth/signup" className="signup-btn ">
                    Kayıt
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
