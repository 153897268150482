import { Button } from "@mui/material";
import { IoSearchOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Pagination, Autoplay, EffectFade, EffectCards } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-cards";

export const TopContent = ({ categories, categoryLoading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex);
  };
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const filteredCategories = categories.filter((category) =>
      category.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSearchResults(filteredCategories);
  };

  return (
    <div className="border ">
      <Swiper
        effect={"cards"}
        onSlideChange={handleSlideChange}
        initialSlide={currentIndex}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      ></Swiper>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, EffectFade]}
        effect="fade"
        fadeEffect={{ crossFade: true }}
        className="mySwiper"
        onSlideChange={handleSlideChange}
        initialSlide={currentIndex}
      >
        <SwiperSlide className="bg-[#c98ac45e]">
          <div className="max-w-[1400px] h-full w-full lg:min-h-[70vh] relative lg:md:grid lg:md:grid-cols-[repeat(2,1fr)] flex flex-col items-center lg:gap-44 xl:gap-60 text-left overflow-x-hidden py-[10%] lg:md:py-0 px-6 lg:px-4 xl:px-[48px] mt-16 mx-auto">
            <div className="overflow-y-clip w-[300px] mb-6 lg:mb-0 xl:w-[500px] md:w-[400px] ">
              <Swiper
                effect={"cards"}
                onSlideChange={handleSlideChange}
                initialSlide={currentIndex}
                grabCursor={false}
                allowTouchMove={false} // Disable swipe gestures
                pagination={false} // Disable pagination
                navigation={false} // Disable navigation
                modules={[EffectCards]}
                className="mySwiper "
                cardsEffect={{ rotate: 50 }}
              >
                <SwiperSlide>
                  <img
                    src="/assets/banner1.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner2.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner3.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner4.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
              </Swiper>
            </div>

            <div className="">
              <h4 className="text-2xl lg:md:text-3xl font-semibold text-[white] mb-6">
                BOYA & BADANA
              </h4>
              <p className="text-xl lg:md:text-2xl font-semibold text-[#e23762] mb-4">
                Boya & Badana işlerinizde deneyimli ustalar arıyorsanız doğru
                yerdesiniz!
              </p>
              <p className="text-lg lg:md:text-xl font-semibold text-white mb-4">
                Ücretsiz ilan vererek, deneyimli ustalar ile görüşebilirsiniz.
              </p>

              <div>
                <div className="inline-flex  w-full flex-col justify-center relative text-gray-500 ">
                  <form className="lg:w-[400px] w-[95%]  lg:h-[55px] h-[45px] flex rounded-md bg-white justify-between space-y-0">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center">
                        <IoSearchOutline className=" text-[#777] w-6 h-6 m-2 " />
                      </div>

                      <input
                        type="text"
                        placeholder="NE ARIYORSUN?"
                        value={searchTerm}
                        onChange={handleSearchChange} // Call handleSearchChange on input change
                        className="lg:flex-1 w-[70%] lg:w-full outline-none"
                      />
                    </div>
                    <Button
                      style={{
                        marginTop:
                          window.innerWidth >= 1024
                            ? "0px !important"
                            : "16px !important",
                      }}
                      className="top-btn w-[30%]   lg:h-[55px] h-[45px] "
                      onClick={() => {
                        if (searchTerm !== "" && searchResults?.length > 0) {
                          navigate(`/category/${searchResults[0]?._id}`);
                        }
                      }}
                    >
                      Ara
                    </Button>
                  </form>
                  {console.log(searchResults)}
                  {searchTerm !== "" && (
                    <div className="absolute left-0 w-full top-full z-10 ">
                      <ul className="bg-white border border-gray-100 rounded p-3">
                        {searchResults?.length > 0 ? (
                          <div className="search-results">
                            {searchResults.map((item, index) => (
                              <li
                                onClick={() =>
                                  navigate(`/category/${item?._id}`)
                                }
                                key={index}
                                className=" p-3 border-b hover:bg-gray-100 hover:rounded cursor-pointer"
                              >
                                {item?.name}
                              </li>
                            ))}
                          </div>
                        ) : (
                          <li className=" p-3  hover:bg-gray-100 hover:rounded cursor-pointer">
                            Sonuç bulunamadı
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop:
                    window.innerWidth >= 1024
                      ? "0px !important"
                      : "48px !important",
                }}
                className="text-[white] pt-3 lg:md:pt-[25px] space-y-2 space-x-2 mt-12 lg:md:mt-0"
              >
                {" "}
                Popüler:{" "}
                <Link
                  to={"/category/6691409a87f6eab8d90bea5f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Hizmetler
                </Link>
                <Link
                  to={"/category/669143bd87f6eab8d90bea94"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Grafik Tasarım
                </Link>
                <Link
                  to={"/category/6691450687f6eab8d90beaac"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Pazarlama{" "}
                </Link>
                <Link
                  to={"/category/669145d9a845c6bf53dcc2e9"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Video ve Animasyon{" "}
                </Link>
                <Link
                  to={"/category/669146ad87f6eab8d90beb2f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Temizlik{" "}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="bg-[#c98ac45e]">
          <div className=" max-w-[1400px] h-full w-full lg:min-h-[70vh] relative lg:md:grid lg:md:grid-cols-[repeat(2,1fr)] flex flex-col items-center lg:gap-44 xl:gap-60 text-left overflow-x-hidden py-[10%] lg:md:py-0 px-6 lg:px-4 xl:px-[48px] mt-16 mx-auto">
            <div className="overflow-y-clip w-[300px] mb-6 lg:mb-0 xl:w-[500px] md:w-[400px] ">
              <Swiper
                effect={"cards"}
                onSlideChange={handleSlideChange}
                initialSlide={currentIndex}
                grabCursor={false}
                allowTouchMove={false} // Disable swipe gestures
                pagination={false} // Disable pagination
                navigation={false} // Disable navigation
                modules={[EffectCards]}
                className="mySwiper "
                cardsEffect={{ rotate: 50 }}
              >
                <SwiperSlide>
                  <img
                    src="/assets/banner2.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <img
                    src="/assets/banner3.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner4.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner1.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="">
              <h4 className="text-2xl lg:md:text-3xl font-semibold text-[white] mb-6">
                NAKLİYE
              </h4>
              <p className="text-xl lg:md:text-2xl font-semibold text-[#e23762] mb-4">
                Nakliye hizmeti arıyorsanız doğru yerdesiniz!
              </p>
              <p className="text-lg lg:md:text-xl font-semibold text-[#fff] mb-4">
                Size özel nakliye çözümleri sunan, güvenilir ve deneyimli taşıma
                firmalarıyla tanışın.
              </p>

              <div>
                <div className="inline-flex  w-full flex-col justify-center relative text-gray-500 ">
                  <form className="lg:w-[400px] w-[95%]  lg:h-[55px] h-[45px] flex rounded-md bg-white justify-between space-y-0">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center">
                        <IoSearchOutline className=" text-[#777] w-6 h-6 m-2 " />
                      </div>

                      <input
                        type="text"
                        placeholder="NE ARIYORSUN?"
                        value={searchTerm}
                        onChange={handleSearchChange} // Call handleSearchChange on input change
                        className="lg:flex-1 w-[70%] lg:w-full outline-none"
                      />
                    </div>
                    <Button
                      style={{
                        marginTop:
                          window.innerWidth >= 1024
                            ? "0px !important"
                            : "16px !important",
                      }}
                      className="top-btn w-[30%]   lg:h-[55px] h-[45px] "
                      onClick={() => {
                        if (searchTerm !== "" && searchResults?.length > 0) {
                          navigate(`/category/${searchResults[0]?._id}`);
                        }
                      }}
                    >
                      Ara
                    </Button>
                  </form>
                  {console.log(searchResults)}
                  {searchTerm !== "" && (
                    <div className="absolute left-0 w-full top-full z-10 ">
                      <ul className="bg-white border border-gray-100 rounded p-3">
                        {searchResults?.length > 0 ? (
                          <div className="search-results">
                            {searchResults.map((item, index) => (
                              <li
                                onClick={() =>
                                  navigate(`/category/${item?._id}`)
                                }
                                key={index}
                                className=" p-3 border-b hover:bg-gray-100 hover:rounded cursor-pointer"
                              >
                                {item?.name}
                              </li>
                            ))}
                          </div>
                        ) : (
                          <li className=" p-3  hover:bg-gray-100 hover:rounded cursor-pointer">
                            Sonuç bulunamadı
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop:
                    window.innerWidth >= 1024
                      ? "0px !important"
                      : "48px !important",
                }}
                className="text-[white] pt-3 lg:md:pt-[25px] space-y-2 space-x-2 mt-12 lg:md:mt-0"
              >
                {" "}
                Popüler:{" "}
                <Link
                  to={"/category/6691409a87f6eab8d90bea5f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Hizmetler
                </Link>
                <Link
                  to={"/category/669143bd87f6eab8d90bea94"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Grafik Tasarım
                </Link>
                <Link
                  to={"/category/6691450687f6eab8d90beaac"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Pazarlama{" "}
                </Link>
                <Link
                  to={"/category/669145d9a845c6bf53dcc2e9"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Video ve Animasyon{" "}
                </Link>
                <Link
                  to={"/category/669146ad87f6eab8d90beb2f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Temizlik{" "}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="bg-[#c98ac45e]">
          <div className="max-w-[1400px] h-full w-full lg:min-h-[70vh] relative lg:md:grid lg:md:grid-cols-[repeat(2,1fr)] flex flex-col items-center lg:gap-44 xl:gap-60 text-left overflow-x-hidden py-[10%] lg:md:py-0 px-6 lg:px-4 xl:px-[48px] mt-16 mx-auto">
            <div className="overflow-y-clip w-[300px] mb-6 lg:mb-0 xl:w-[500px] md:w-[400px] ">
              <Swiper
                effect={"cards"}
                onSlideChange={handleSlideChange}
                initialSlide={currentIndex}
                grabCursor={false}
                allowTouchMove={false} // Disable swipe gestures
                pagination={false} // Disable pagination
                navigation={false} // Disable navigation
                modules={[EffectCards]}
                className="mySwiper "
                cardsEffect={{ rotate: 50 }}
              >
                <SwiperSlide>
                  <img
                    src="/assets/banner3.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <img
                    src="/assets/banner4.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner1.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner2.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="">
              <h4 className="text-2xl lg:md:text-3xl font-semibold text-[white] mb-6">
                TEMİZLİK HİZMETLERİ
              </h4>
              <p className="text-xl lg:md:text-2xl font-semibold text-[#e23762] mb-4">
                Temizlik işlerinizde deneyimli profesyonelleri arıyorsanız doğru
                yerdesiniz!
              </p>
              <p className=" lg:md:text-xl text-lg font-semibold text-[#fff] mb-4">
                Ücretsiz ilan vererek, temizlik profesyonelleri ile
                görüşebilirsiniz.
              </p>
              <div>
                <div className="inline-flex  w-full flex-col justify-center relative text-gray-500 ">
                  <form className="lg:w-[400px] w-[95%]  lg:h-[55px] h-[45px] flex rounded-md bg-white justify-between space-y-0">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center">
                        <IoSearchOutline className=" text-[#777] w-6 h-6 m-2 " />
                      </div>

                      <input
                        type="text"
                        placeholder="NE ARIYORSUN?"
                        value={searchTerm}
                        onChange={handleSearchChange} // Call handleSearchChange on input change
                        className="lg:flex-1 w-[70%] lg:w-full outline-none"
                      />
                    </div>
                    <Button
                      style={{
                        marginTop:
                          window.innerWidth >= 1024
                            ? "0px !important"
                            : "16px !important",
                      }}
                      className="top-btn w-[30%]   lg:h-[55px] h-[45px] "
                      onClick={() => {
                        if (searchTerm !== "" && searchResults?.length > 0) {
                          navigate(`/category/${searchResults[0]?._id}`);
                        }
                      }}
                    >
                      Ara
                    </Button>
                  </form>
                  {console.log(searchResults)}
                  {searchTerm !== "" && (
                    <div className="absolute left-0 w-full top-full z-10 ">
                      <ul className="bg-white border border-gray-100 rounded p-3">
                        {searchResults?.length > 0 ? (
                          <div className="search-results">
                            {searchResults.map((item, index) => (
                              <li
                                onClick={() =>
                                  navigate(`/category/${item?._id}`)
                                }
                                key={index}
                                className=" p-3 border-b hover:bg-gray-100 hover:rounded cursor-pointer"
                              >
                                {item?.name}
                              </li>
                            ))}
                          </div>
                        ) : (
                          <li className=" p-3  hover:bg-gray-100 hover:rounded cursor-pointer">
                            Sonuç bulunamadı
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop:
                    window.innerWidth >= 1024
                      ? "0px !important"
                      : "48px !important",
                }}
                className="text-[white] pt-3 lg:md:pt-[25px] space-y-2 space-x-2 mt-12 lg:md:mt-0"
              >
                {" "}
                Popüler:{" "}
                <Link
                  to={"/category/6691409a87f6eab8d90bea5f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Hizmetler
                </Link>
                <Link
                  to={"/category/669143bd87f6eab8d90bea94"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Grafik Tasarım
                </Link>
                <Link
                  to={"/category/6691450687f6eab8d90beaac"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Pazarlama{" "}
                </Link>
                <Link
                  to={"/category/669145d9a845c6bf53dcc2e9"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Video ve Animasyon{" "}
                </Link>
                <Link
                  to={"/category/669146ad87f6eab8d90beb2f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Temizlik{" "}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="bg-[#c98ac45e]">
          <div className="max-w-[1400px] h-full w-full lg:min-h-[70vh] relative lg:md:grid lg:md:grid-cols-[repeat(2,1fr)] flex flex-col items-center lg:gap-44 xl:gap-60 text-left overflow-x-hidden py-[10%] lg:md:py-0 px-6 lg:px-4 xl:px-[48px] mt-16 mx-auto">
            <div className="overflow-y-clip w-[300px] mb-6 xl:mb-0 xl:w-[500px] md:w-[400px] ">
              <Swiper
                effect={"cards"}
                onSlideChange={handleSlideChange}
                initialSlide={currentIndex}
                grabCursor={false}
                allowTouchMove={false} // Disable swipe gestures
                pagination={false} // Disable pagination
                navigation={false} // Disable navigation
                modules={[EffectCards]}
                className="mySwiper "
                cardsEffect={{ rotate: 50 }}
              >
                <SwiperSlide>
                  <img
                    src="/assets/banner4.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>{" "}
                <SwiperSlide>
                  <img
                    src="/assets/banner1.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner2.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="/assets/banner3.jpg"
                    alt="banner1.png"
                    className="xl:h-[500px] xl:w-[500px] md:w-[400px] md:h-[400px] h-[300px] w-[300px] rounded-xl  opacity-90"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="">
              <h4 className="text-2xl lg:md:text-3xl font-semibold text-[white] mb-6">
                KOCFREELANCING İLE
              </h4>
              <p className="text-xl lg:md:text-2xl font-semibold text-[#e23762] mb-4">
                Birçok işinizde deneyimli profesyoneller sizinle.
              </p>
              <p className=" lg:md:text-xl text-lg font-semibold text-[#fff] mb-4">
                Size özel çözümler sunan, güvenilir ve deneyimli serbest
                çalışanlar ile tanışın.
              </p>
              <div>
                <div className="inline-flex  w-full flex-col justify-center relative text-gray-500 ">
                  <form className="lg:w-[400px] w-[95%]  lg:h-[55px] h-[45px] flex rounded-md bg-white justify-between space-y-0">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center">
                        <IoSearchOutline className=" text-[#777] w-6 h-6 m-2 " />
                      </div>

                      <input
                        type="text"
                        placeholder="NE ARIYORSUN?"
                        value={searchTerm}
                        onChange={handleSearchChange} // Call handleSearchChange on input change
                        className="lg:flex-1 w-[70%] lg:w-full outline-none"
                      />
                    </div>
                    <Button
                      style={{
                        marginTop:
                          window.innerWidth >= 1024
                            ? "0px !important"
                            : "16px !important",
                      }}
                      className="top-btn w-[30%]   lg:h-[55px] h-[45px] "
                      onClick={() => {
                        if (searchTerm !== "" && searchResults?.length > 0) {
                          navigate(`/category/${searchResults[0]?._id}`);
                        }
                      }}
                    >
                      Ara
                    </Button>
                  </form>
                  {console.log(searchResults)}
                  {searchTerm !== "" && (
                    <div className="absolute left-0 w-full top-full z-10 ">
                      <ul className="bg-white border border-gray-100 rounded p-3">
                        {searchResults?.length > 0 ? (
                          <div className="search-results">
                            {searchResults.map((item, index) => (
                              <li
                                onClick={() =>
                                  navigate(`/category/${item?._id}`)
                                }
                                key={index}
                                className=" p-3 border-b hover:bg-gray-100 hover:rounded cursor-pointer"
                              >
                                {item?.name}
                              </li>
                            ))}
                          </div>
                        ) : (
                          <li className=" p-3  hover:bg-gray-100 hover:rounded cursor-pointer">
                            Sonuç bulunamadı
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  marginTop:
                    window.innerWidth >= 1024
                      ? "0px !important"
                      : "48px !important",
                }}
                className="text-[white] pt-3 lg:md:pt-[25px] space-y-2 space-x-2 mt-12 lg:md:mt-0"
              >
                {" "}
                Popüler:{" "}
                <Link
                  to={"/category/6691409a87f6eab8d90bea5f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Hizmetler
                </Link>
                <Link
                  to={"/category/669143bd87f6eab8d90bea94"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Grafik Tasarım
                </Link>
                <Link
                  to={"/category/6691450687f6eab8d90beaac"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Dijital Pazarlama{" "}
                </Link>
                <Link
                  to={"/category/669145d9a845c6bf53dcc2e9"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Video ve Animasyon{" "}
                </Link>
                <Link
                  to={"/category/669146ad87f6eab8d90beb2f"}
                  className="inline-block text-[#404145]  leading-[0.5] text-[11px] font-medium transition-all duration-[0.55s] ease-[ease] p-[11px] rounded-[30px]  hover:translate-x-2  bg-[#d7d6d6]"
                >
                  Temizlik{" "}
                </Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
