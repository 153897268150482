import { Container } from "@mui/material";
import Description from "../ui/Description";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";

export const PopularCategory = ({ categories, categoryLoading }) => {
  const navigate = useNavigate();

  // State to track hover on category
  const [hoveredCategory, setHoveredCategory] = useState(null);
  return (
    <div className="bg-[whitesmoke]">
      <div>
        <h1 className="text-lg lg:md:text-3xl text-[#03446a] text-center w-full mb-7 lg:md:mb-[50px] pt-7 md:pt-[45px] font-bold">
          Popüler Kategoriler
        </h1>
      </div>
      <Container>
        <div className=" max-w-[1400px] place-items-center  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-x-6 gap-y-6 md:gap-y-24 lg:md:mt-20 mb-14 mx-auto w-full px-4">
          {categoryLoading ? (
            <div className="flex items-center col-span-1 md:col-span-2 lg:col-span-3 justify-center h-[50vh]  mb-14 mx-auto">
              <div className="loader"></div>
            </div>
          ) : categories?.length > 0 ? (
            [...categories].slice(0, 6).map((item, index) => (
              <div
                className="flip-container  shadow-lg md:w-[300px]  md:h-[300px] w-[250px] h-[250px] flex flex-col justify-center relative cursor-pointer"
                key={index}
                style={{ border: "2px solid #a9cfa9" }}
                onClick={() => navigate(`/category/${item?._id}`)}
                onMouseEnter={() => setHoveredCategory(index)}
                onMouseLeave={() => setHoveredCategory(null)}
              >
                <div className="flipper flex flex-col items-center justify-center">
                  <div className="front relative flex flex-col rounded-xl bg-white  shadow cursor-pointer group transition-all text-secondary">
                    <div className="h-full  flex items-center justify-center">
                      <h5 className=" flex flex-col items-center justify-center font-sans lg:text-xl text-lg font-semiboldtext-blue-gray-900 antialiased">
                        {item?.name}
                        <p className=" font-medium lg:text-md text-sm">
                          Toplam Serbest Çalışan : {item?.profilesCount ?? 0}
                        </p>
                      </h5>
                    </div>
                  </div>
                  <div className="back w-full transition-all  absolute top-[-6.3rem] md:top-[-9.3rem]">
                    {item?.image && (
                      <img
                        src={item?.image}
                        alt="profile"
                        className={`w-full transition-all md:h-[300px] h-[200px] overflow-hidden ${
                          hoveredCategory === index
                            ? "opacity-100"
                            : "opacity-0"
                        }`}
                        style={{
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-center justify-center">
              <img
                src="/assets/404.png"
                width={400}
                height={400}
                alt="Veri bulunamadı"
              />
            </div>
          )}
        </div>
        <div className="flex items-center justify-center my-8">
          <Link
            className=" rounded-3xl  !py-3 !px-8    bg-primary hover:bg-opacity-90 transition-all border !text-[#fff] text-center active:scale-95 "
            to="/categories"
          >
            Tüm Kategorileri Gör
          </Link>
        </div>
      </Container>
    </div>
  );
};
