import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  registerUser,
  resendEmail,
  getCategories,
  getCountries,
  getSubCategoriesById,
} from "../../axios/axios";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import DateRangeIcon from "@mui/icons-material/DateRange";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { MdHome } from "react-icons/md";
import { IconButton, Stack, Typography } from "@mui/material";
import Grow from "@mui/material/Grow";
import { Link } from "react-router-dom";
import Select from "react-tailwindcss-select";
import { LockReset } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { editInfo, postInitiateChat } from "../../axios/axios";
import { turkishCities } from "../../constants/data";
import Cookies from "js-cookie";
import genTestUserSig from "../../helper/GenerateTestUserSig";
import axios from "axios";

const SignupPage = () => {
  // State variables
  const [state, setState] = useState("signup");

  const [timer, setTimer] = useState(60);
  const [loading, setLoading] = useState({
    signup: false,
    resendEmail: false,
    fetchData: false,
    info: false,
  });
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [characterCount, setCharacterCount] = useState(0);
  const [userId, setUserId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);
  const [disableResendButton, setDisableResendButton] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Form validation using react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  // Fetch categories and countries data
  useEffect(() => {
    const fetchData = async () => {
      setLoading({ ...loading, fetchData: true });
      try {
        // Fetch categories data
        const categoriesResponse = await dispatch(getCategories(""));
        setCategoryOptions(
          categoriesResponse?.payload?.data?.map((item) => ({
            value: item._id,
            label: item.name,
          }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setLoading({ ...loading, fetchData: false });
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedCategory) {
        try {
          // Fetch subcategories data based on the selected category
          const subCategoriesResponse = await dispatch(
            getSubCategoriesById({ id: selectedCategory.value })
          );

          // Ensure subCategoriesResponse is not null or undefined before accessing its properties
          const options =
            subCategoriesResponse?.payload?.data?.map((item) => ({
              value: item._id,
              label: item.name,
            })) || [];

          // Logging for debugging
          console.log("Subcategory options:", options, selectedCategory.value);

          // Set the subcategory options
          setSubCategoryOptions(options);
        } catch (error) {
          // Handle errors gracefully
          console.error("Error fetching subcategories:", error);
          // You may want to setSubCategoryOptions([]) or handle the error differently
        }
      }
    };
    fetchData();
  }, [dispatch, selectedCategory]);

  // Handle form submission
  const onsubmit = async (data) => {
    // Calculate user's age based on DOB
    const [year, month, day] = data.dob.split("-");
    const birthDate = new Date(year, month - 1, day);
    const currentDate = new Date();

    // Calculate the difference in years, months, and days
    const yearDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = currentDate.getMonth() - birthDate.getMonth();
    const dayDiff = currentDate.getDate() - birthDate.getDate();

    // Calculate the float age
    let age = yearDiff + monthDiff / 12 + dayDiff / 365.25;

    if (age < 18) {
      setError("dob", {
        type: "manual",
        message: "En az 18 yaşında olmalısınız.",
      });
      return;
    }

    // Concatenate the prefix with the phone number
    const phoneNumberWithPrefix = `+90${data.phone_number}`;

    setLoading({ ...loading, signup: true });
    try {
      // Register user
      const response = await dispatch(
        registerUser({
          dynamicParams: {},
          bodyData: {
            ...data,
            phone_number: phoneNumberWithPrefix,
            country: "Türkiye",
            subCategory: selectedSubCategory.value,
            city: selectedCity.value,
            category: selectedCategory.value,
            role: "user",
          },
        })
      );
      if (
        response.payload.statusCode === 200 ||
        response.payload.statusCode === 201
      ) {
        setUserId(response.payload.data._id);
        setUserEmail(data.email);
        setState("info");
        console.log(response.payload);
        dispatch(postInitiateChat(response.payload.data.email))
          .then((response) => {
            // Handle successful response
            console.log("API call successful:", response);
          })
          .catch((error) => {
            // Handle error
            console.error("API call failed:", error);
          });
      }
    } catch (error) {
      console.error("Registration failed:", error);
    } finally {
      setLoading({ ...loading, signup: false });
    }
  };

  // Handle info update
  const handleInfoUpdate = async (data) => {
    setLoading({ ...loading, info: true });
    try {
      const response = await dispatch(
        editInfo({
          dynamicParams: {
            userId: userId,
          },
          bodyData: data,
        })
      );
      if (
        response.payload.statusCode === 200 ||
        response.payload.statusCode === 201
      ) {
        setState("verify");
        // const res = await axios.post(
        //   `${process.env.REACT_APP_BASE_URL}/auth/resendEmail/${userEmail}`
        // );
        // console.log(res);
        // if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
        //   // Start the timer
        //   setTimer(60);
        //   setDisableResendButton(true);
        // }
        await dispatch(
          resendEmail({
            dynamicParams: { email: userEmail },
            bodyData: {},
          })
        );
      }
    } catch (error) {
      console.error("Resend email failed:", error);
    } finally {
      setLoading({ ...loading, resendEmail: false });
    }
  };
  // Handle resending verification email

  const handleResendEmail = async () => {
    setLoading({ ...loading, resendEmail: true });
    try {
      const response = await dispatch(
        resendEmail({
          dynamicParams: { email: userEmail },
          bodyData: {},
        })
      );
      if (
        response.payload.statusCode === 200 ||
        response.payload.statusCode === 201
      ) {
        // Start the timer
        setTimer(60);
        setDisableResendButton(true);
      }

      // const res = await axios.post(
      //   `${process.env.REACT_APP_BASE_URL}/auth/resendEmail/${userEmail}`
      // );
      // if (res?.data?.statusCode === 200 || res?.data?.statusCode === 201) {
      //   // Start the timer
      //   setTimer(60);
      //   setDisableResendButton(true);
      // }
    } catch (error) {
      console.error("Resend email failed:", error);
    } finally {
      setLoading({ ...loading, resendEmail: false });
    }
  };
  // Update timer every second
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setDisableResendButton(false);
      clearInterval(interval);
    }
    if (timer > 60 || timer < 0) {
      setTimer(60);
    }
    return () => clearInterval(interval);
  }, [timer]);
  return (
    <Stack
      className="auth-back overflow-hidden"
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grow in={true}>
        <Stack direction="row" justifyContent="center">
          {state === "signup" && (
            <div className="register-wrap rounded-none md:rounded-[10px]">
              <div
                className="flex items-center justify-center text-gray-300 cursor-pointer mx-auto gap-x-1 my-4"
                onClick={() => navigate(`/`)}
              >
                <MdHome className="text-xl " />
                <p className="break-all text-lg hover:underline ">
                  KocFreelancing
                </p>
              </div>
              <h4 className="auth-title">Kayıt Ol</h4>
              <form onSubmit={handleSubmit(onsubmit)}>
                <Stack direction="row" justifyContent="center">
                  <Stack sx={{ width: "80%" }}>
                    <Stack direction="row">
                      <div className="p-1 w-full">
                        <label
                          htmlFor="category"
                          className="col-span-full font-medium my-1"
                        >
                          Ad
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          {...register("first_name", { required: true })}
                          className=" bg-[#1f2029] focus:text-[#a09fa3] text-base border-none rounded-md  my-1 p-3 w-full !outline-none  font-poppins"
                          placeholder="Ad"
                        />
                        {errors.first_name && (
                          <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                            Bu alan gereklidir
                          </span>
                        )}
                      </div>
                      <div className="p-1 w-full">
                        <label
                          htmlFor="category"
                          className="col-span-full font-medium my-1"
                        >
                          Soyad
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          name="lastName"
                          {...register("lastName", { required: true })}
                          className=" bg-[#1f2029] my-1  text-base border-none rounded-md   p-3 w-full !outline-none  font-poppins"
                          placeholder="Soyad"
                        />
                        {errors.lastName && (
                          <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                            Bu alan gereklidir
                          </span>
                        )}
                      </div>
                    </Stack>
                    <div className="flex flex-col lg:md:flex-row items-center justify-between gap-x-2">
                      <div className="w-full">
                        <label
                          htmlFor="category"
                          className="col-span-full font-medium my-1 "
                        >
                          Kategori
                        </label>
                        <Select
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e)}
                          options={categoryOptions}
                          isSearchable
                          loading={loading.signup}
                          primaryColor={"lime"}
                          placeholder="Kategori Seçin"
                          classNames={{
                            searchIcon:
                              "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                            searchBox:
                              "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                            menuButton: ({ isDisabled }) =>
                              `flex rounded-lg text-gray-100 my-1 py-1 px-3  shadow-sm transition-all duration-300 focus:outline-none ${
                                isDisabled
                                  ? "text-gray-400"
                                  : "bg-[#1f2029] hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                              }`,
                            menu: "absolute z-10 w-full bg-[#1f2029] shadow-lg  rounded py-2 mt-1.5 rounded-lg text-gray-300",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 py-1 px-3 rounded-lg cursor-pointer select-none truncate rounded text-gray-300 ${
                                isSelected
                                  ? `bg-gray-300 text-gray-900`
                                  : `hover:bg-gray-300 hover:text-gray-900`
                              }`,
                            searchIcon:
                              "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                            searchBox:
                              "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                            list: "max-h-[30vh] overflow-y-scroll",
                          }}
                          style={{ padding: ".5rem" }}
                        />
                        {!selectedCategory && (
                          <span className="w-full text-red-400  -mt-1 cursor-context-menu">
                            Bu alan gereklidir
                          </span>
                        )}
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="subCategory"
                          className="col-span-full font-medium my-1"
                        >
                          Alt Kategori
                        </label>
                        <Select
                          value={selectedSubCategory}
                          onChange={(e) => setSelectedSubCategory(e)}
                          options={subCategoryOptions}
                          isSearchable
                          loading={loading.signup}
                          isDisabled={!selectedCategory}
                          primaryColor={"lime"}
                          placeholder="Alt Kategori Seçin"
                          classNames={{
                            searchIcon:
                              "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                            searchBox:
                              "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                            menuButton: ({ isDisabled }) =>
                              `flex rounded-lg text-gray-100 my-1 py-1 px-3  shadow-sm transition-all duration-300 focus:outline-none ${
                                isDisabled
                                  ? "text-gray-400"
                                  : "bg-[#1f2029] hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                              }`,
                            menu: "absolute z-10 w-full bg-[#1f2029] shadow-lg  rounded py-2 mt-1.5 rounded-lg text-gray-300",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 py-1 px-3 rounded-lg cursor-pointer select-none truncate rounded text-gray-300 ${
                                isSelected
                                  ? `bg-gray-300 text-gray-900`
                                  : `hover:bg-gray-300 hover:text-gray-900`
                              }`,
                            searchIcon:
                              "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                            searchBox:
                              "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                            list: "max-h-[30vh] overflow-y-scroll",
                          }}
                          style={{ padding: ".5rem" }}
                        />
                        {!selectedSubCategory && (
                          <span className="w-full text-red-400  -mt-1 cursor-context-menu">
                            Bu alan gereklidir
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col lg:md:flex-row items-center justify-between gap-x-2">
                      <div className="w-full">
                        <label
                          htmlFor="country"
                          className="col-span-full font-medium "
                        >
                          Ülke
                        </label>
                        <input
                          type="text"
                          id="country"
                          name="country"
                          className=" bg-[#1f2029] my-1  text-base border-none rounded-md   p-3 w-full !outline-none  font-poppins"
                          placeholder="Ülke"
                          value="Türkiye"
                          disabled
                        />
                      </div>
                      <div className="w-full">
                        <label
                          htmlFor="city"
                          className="col-span-full font-medium my-1"
                        >
                          İl
                        </label>
                        <div className="relative">
                          <Select
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e)}
                            options={turkishCities}
                            isSearchable
                            primaryColor={"lime"}
                            placeholder="İl Seçin"
                            classNames={{
                              searchIcon:
                                "absolute top-2 w-5 h-5  pb-0.5 ml-2 text-gray-500",
                              searchBox:
                                "border w-full rounded  !placeholder-white pl-8 outline-none focus:outline-none",
                              menuButton: ({ isDisabled }) =>
                                `flex rounded-lg text-gray-100 my-1 py-1 px-3  shadow-sm transition-all duration-300 focus:outline-none ${
                                  isDisabled
                                    ? "text-gray-400 bg-[#1f2029]"
                                    : "bg-[#1f2029] hover:border-gray-400 focus:border-primary focus:ring focus:ring-primary/10"
                                }`,
                              menu: "absolute z-10 w-full bg-[#1f2029] shadow-lg  rounded py-2 mt-1.5 rounded-lg text-gray-300",
                              listItem: ({ isSelected }) =>
                                `block transition duration-200 py-1 px-3 rounded-lg cursor-pointer select-none truncate rounded text-gray-300 ${
                                  isSelected
                                    ? `bg-gray-300 text-gray-900`
                                    : `hover:bg-gray-300 hover:text-gray-900`
                                }`,
                              list: "max-h-[30vh] overflow-y-scroll",
                            }}
                            style={{ padding: ".5rem" }}
                          />
                        </div>
                      </div>
                    </div>
                    {(!selectedCity || selectedCity === "") && (
                      <span className="w-full text-red-400  -mt-1 cursor-context-menu lg:ml-[50%]">
                        Bu alan gereklidir
                      </span>
                    )}
                    <div className="p-1">
                      <label
                        htmlFor="dob"
                        className="col-span-full font-medium "
                      >
                        Doğum Tarihi
                      </label>
                      <div className="bg-[#1f2029] rounded-md my-1  p-3 w-full flex items-center ">
                        <DateRangeIcon sx={{ color: "#ffeba7" }} />
                        <input
                          type="date"
                          placeholder="MM/DD/YYYY"
                          max={new Date().toISOString().split("T")[0]}
                          {...register("dob", {
                            required: "Lütfen doğum tarihinizi giriniz",
                          })}
                          className=" mx-2 bg-[#1f2029]  text-base border-none  w-full !outline-none  font-poppins"
                        />
                      </div>
                    </div>
                    {errors.dob && (
                      <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                        {errors.dob.message}
                      </span>
                    )}
                    <div className="p-1">
                      <label
                        htmlFor="Telefon"
                        className="col-span-full font-medium"
                      >
                        Telefon
                      </label>
                      <div className="bg-[#1f2029] rounded-md my-1 p-3 w-full flex items-center">
                        <LocalPhoneIcon sx={{ color: "#ffeba7" }} />
                        <span className="text-base ">+90</span>
                        <input
                          type="tel"
                          id="phone_number"
                          name="phone_number"
                          {...register("phone_number", {
                            required: "Bu alan gereklidir",
                            pattern: {
                              value: /^\d{10}$/,
                              message:
                                "Telefon numarası 10 haneli olmalıdır. Örn: 5xxxxxxxxx",
                            },
                          })}
                          className="ml-2 bg-[#1f2029] text-base border-none w-full !outline-none font-poppins"
                          placeholder="örn. 5xxxxxxxxx"
                        />
                      </div>
                    </div>
                    {errors.phone_number && (
                      <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                        {errors.phone_number.message}
                      </span>
                    )}

                    <div className="p-1">
                      <label
                        htmlFor="email"
                        className="col-span-full font-medium my-1"
                      >
                        email
                      </label>
                      <div className="bg-[#1f2029] rounded-md my-1  p-3 w-full flex items-center ">
                        <MarkunreadIcon sx={{ color: "#ffeba7" }} />
                        <input
                          type="email"
                          id="email"
                          name="email"
                          {...register("email", { required: true })}
                          className=" mx-2 bg-[#1f2029]  text-base border-none  w-full !outline-none  font-poppins"
                          placeholder="örn. example@gmail.com"
                        />
                      </div>
                    </div>
                    {errors.email && (
                      <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                        Bu alan gereklidir
                      </span>
                    )}
                    <div className="p-1">
                      <label
                        htmlFor="country"
                        className="col-span-full font-medium my-2"
                      >
                        Şifre
                      </label>
                      <div className="bg-[#1f2029] rounded-md  my-1 p-3 w-full flex items-center ">
                        <LockReset sx={{ color: "#ffeba7" }} />
                        <input
                          type="password"
                          id="password"
                          name="password"
                          {...register("password", {
                            required: true,
                            minLength: 6,
                          })}
                          className=" mx-2 bg-[#1f2029]  text-base border-none  w-full !outline-none  font-poppins"
                          placeholder="Şifre"
                        />
                      </div>
                    </div>
                    {errors.password && (
                      <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                        Şifre en az 6 karakter olmalıdır
                      </span>
                    )}
                    <div className="flex items-center">
                      <div className="inline-flex items-center">
                        <label
                          className="relative flex cursor-pointer items-center rounded-full p-2"
                          htmlFor="checkbox-1"
                          data-ripple-dark="true"
                        >
                          <input
                            type="checkbox"
                            id="checkbox-1"
                            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border  transition-all before:absolute checked:border-[#1f2029] checked:bg-[#1f2029]"
                            checked={isChecked}
                            onChange={(e) => setIsChecked(!isChecked)}
                            required
                          />
                          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3.5 w-3.5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              strokeWidth={1}
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </label>
                      </div>
                      <p className="break-all text-base">
                        <Link
                          className="underline font-medium cursor-pointer hover:no-underline"
                          to="/protection"
                        >
                          Kullanıcı Sözleşmesi
                        </Link>
                      </p>
                    </div>
                    <div className="flex items-center">
                      <div className="inline-flex items-center">
                        <label
                          className="relative flex cursor-pointer items-center rounded-full p-2"
                          htmlFor="checkbox-1"
                          data-ripple-dark="true"
                        >
                          <input
                            type="checkbox"
                            id="checkbox-1"
                            className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border  transition-all before:absolute checked:border-[#1f2029] checked:bg-[#1f2029]"
                            checked={isChecked2}
                            onChange={(e) => setIsChecked2(!isChecked2)}
                            required
                          />
                          <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-3.5 w-3.5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              stroke="currentColor"
                              strokeWidth={1}
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        </label>
                      </div>
                      <p className="break-all text-base">
                        <Link
                          className="underline font-medium cursor-pointer hover:no-underline"
                          to="/terms"
                        >
                          KVKK Aydınlatma Metni
                        </Link>
                      </p>
                    </div>
                  </Stack>
                </Stack>
                <Stack direction="row" justifyContent="center">
                  {loading.signup ? (
                    <div className="btn !cursor-context-menu px-16 hover:bg-[#ffeaa7af]">
                      <div className="loaderAuth mx-auto"></div>{" "}
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn"
                      disabled={!isChecked || !isChecked2}
                    >
                      Kayıt Ol
                    </button>
                  )}
                </Stack>
              </form>
              <Stack direction="row" justifyContent="center" alignSelf="center">
                <Typography sx={{ color: "#c4c3ca" }}>
                  Zaten hesabınız var mı?{" "}
                </Typography>{" "}
                <Link to="/auth/login" className="auth-change-btn mx-1">
                  {" "}
                  Giriş Yap
                </Link>
              </Stack>
            </div>
          )}
          {state === "info" ? (
            <form
              className="register-wrap"
              onSubmit={handleSubmit(handleInfoUpdate)}
            >
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                marginBottom={2}
              >
                <h4 className="text-xl ml-8">Bize kendinden bahset</h4>
              </Stack>
              <Stack direction="row" justifyContent="center" paddingX="45px">
                <Stack sx={{ width: "100%" }}>
                  <div className="p-1">
                    <label
                      htmlFor="Title"
                      className="col-span-full font-medium "
                    >
                      Çalışma Unvanınız
                    </label>
                    <div className="bg-[#1f2029] rounded-md my-1  p-3 w-full flex items-center ">
                      <input
                        type="text"
                        id="sub_title"
                        name="sub_title"
                        {...register("sub_title", { required: true })}
                        className=" mx-2 bg-[#1f2029]  text-base border-none  w-full !outline-none  font-poppins"
                        placeholder="örn. Kıdemli Kalite Güvence Mühendisi"
                      />
                    </div>
                  </div>
                  {errors.sub_title && (
                    <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                      Bu alan gereklidir
                    </span>
                  )}
                  <div className="p-1">
                    <label
                      htmlFor="hourly_rate"
                      className="col-span-full font-medium"
                    >
                      Saatlik Ücretiniz (₺)
                    </label>
                    <div className="bg-[#1f2029] rounded-md my-1 p-3 w-full flex items-center">
                      <input
                        type="number"
                        id="hourly_rate"
                        name="hourly_rate"
                        {...register("hourly_rate", {
                          required: "Bu alan gereklidir",
                          min: {
                            value: 1,
                            message: "Saatlik ücret 1₺'den fazla olmalıdır",
                          },
                        })}
                        className="mx-2 bg-[#1f2029] text-base border-none w-full !outline-none font-poppins"
                        placeholder="örn. 20"
                      />
                    </div>
                    {errors.hourly_rate && (
                      <span className="w-full text-red-400 -mt-1 cursor-context-menu ml-1">
                        {errors.hourly_rate.message}
                      </span>
                    )}
                  </div>

                  <div className="p-1">
                    <label
                      htmlFor="description"
                      className="col-span-full font-medium my-1"
                    >
                      Açıklama
                    </label>
                    <div className="bg-[#1f2029] rounded-md my-1  p-3 w-full flex items-center ">
                      <textarea
                        rows={6}
                        id="description"
                        name="description"
                        {...register("description", {
                          required: true,
                          minLength: 100,
                          maxLength: 5000,
                        })}
                        className="mx-2 bg-[#1f2029] text-base border-none w-full !outline-none font-poppins"
                        placeholder="Yazılım geliştirme alanında uzmanlaşmış bir yazılımcıyım ve [x] yılı aşkın süredir bu sektörde
                          çalışmaktayım. Bilgisayar mühendisliği bölümünden mezun olduktan sonra, çeşitli projelerde yer
                          alarak geniş bir teknoloji yelpazesi üzerinde deneyim kazandım. Uzmanlık alanlarım arasında;
                          [Dijital Hizmetler dilleri, örneğin: Java, Python, JavaScript], [çerçeveler, örneğin: React, Angular, Spring],
                          ve [teknolojiler, örneğin: AWS, Docker, Kubernetes] bulunmaktadır. Hem ön yüz (front-end) hem de
                          arka yüz (back-end) geliştirme konularında deneyimliyim ve tam yığın (full-stack) geliştirme
                          yapabilmekteyim."
                        onChange={(e) =>
                          setCharacterCount(e.target.value.length)
                        }
                      />
                    </div>
                    <p className="break-all text-end text-gray-300 text-sm">
                      {characterCount > 0
                        ? `${5000 - characterCount} karakter kaldı`
                        : `Maksimum ${5000 - characterCount} karakter`}
                    </p>
                    {errors.description?.type === "required" && (
                      <span className="text-red-400">Bu alan gereklidir</span>
                    )}
                    {errors.description?.type === "minLength" && (
                      <span className="text-red-400">
                        Açıklama en az 100 karakter olmalıdır
                      </span>
                    )}
                    {errors.description?.type === "maxLength" && (
                      <span className="text-red-400">
                        Açıklama 5000 karakteri aşamaz{" "}
                      </span>
                    )}
                  </div>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="center">
                {loading.info ? (
                  <div className="btn !cursor-context-menu px-16 hover:bg-[#ffeaa7af]">
                    <div className="loaderAuth mx-auto"></div>{" "}
                  </div>
                ) : (
                  <button className="btn " type="submit">
                    <p>Gönder</p>{" "}
                  </button>
                )}
              </Stack>
            </form>
          ) : state === "verify" ? (
            <div className="reset-wrap">
              <Stack
                direction="row"
                justifyContent="start"
                alignItems="center"
                marginBottom={2}
              >
                <IconButton
                  onClick={() => setState("info")}
                  sx={{ marginLeft: "20px" }}
                >
                  <ArrowBackIosNewIcon sx={{ color: "grey" }} />
                </IconButton>
                <h4 className="text-xl">E-mail adresinizi doğrulayın</h4>
              </Stack>
              <Stack
                direction="column"
                justifyContent="start"
                alignSelf="center"
                paddingX="45px"
              >
                <Typography sx={{ color: "#c4c3ca" }}>
                  Gelen kutunuza bir doğrulama e-maili gönderdik. Devam etmek
                  için e-mail adresinizi doğrulayın.{" "}
                </Typography>
                <br />
                <Typography sx={{ color: "#c4c3ca" }}>
                  Bir e-posta almadınız mı?{" "}
                  {timer < 1 ? (
                    <span
                      className={`auth-change-btn cursor-pointer ${
                        disableResendButton ? "hidden" : ""
                      }`}
                      onClick={() => handleResendEmail()}
                    >
                      Elektronik postayı tekrar gönder.
                    </span>
                  ) : (
                    <span>{`E-postayı ${timer} saniye içinde yeniden gönderin. `}</span>
                  )}
                </Typography>
              </Stack>
            </div>
          ) : state === "loggedIn" ? (
            <div className="reset-wrap">
              <Stack direction="row" justifyContent="start" alignItems="center">
                <h4 className="auth-title ml-5">Burada hiçbir şey yok</h4>
              </Stack>
              <Stack
                direction="column"
                justifyContent="start"
                alignSelf="center"
                paddingX="45px"
              >
                <Typography sx={{ color: "#c4c3ca" }}>
                  Zaten giriş yaptınız.
                </Typography>
                <br />
                <Typography sx={{ color: "#c4c3ca" }}>
                  <Link to="/" className="auth-change-btn cursor-pointer">
                    Evine geri dön.
                  </Link>
                </Typography>
              </Stack>
            </div>
          ) : null}
        </Stack>
      </Grow>
    </Stack>
  );
};

export default SignupPage;
