import { Container, Stack } from "@mui/material";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

export const FindWork = () => {
  return (
    <div className="w-full bg-[#90bc8242]  px-0 py-[45px]">
      <div className="overflow-hidden mx-auto my-0">
        <Container>
          <Stack
            sx={{ position: "relative" }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div className="">
              <h1 className="text-[rgb(53,185,0)] xs:text-lg lg:md:text-3xl capitalize mb-5 lg:md:text-start text-center font-bold">
                YETENEĞİN DOĞRULTUSUNDA İŞ BUL!
              </h1>
              <p className=" text-[rgb(92,91,91)] lg:md:text-xl text-[16px]   text-left mb-[25px] flex  gap-x-2">
                <div>
                  <FaCheckCircle className="w-5 h-5 mt-1 "></FaCheckCircle>{" "}
                </div>
                Birlikte çalışmaktan heyecan duyduğumuz işverenlerimiz ile
                tanışın. Kariyerinizi ve işinizi zirveye taşıyın.
              </p>
              <p className=" text-[rgb(92,91,91)] lg:md:text-xl text-[16px]   text-left mb-[25px] flex  gap-x-2">
                <div>
                  <FaCheckCircle className="w-5 h-5 mt-1 "></FaCheckCircle>{" "}
                </div>
                Serbest kariyerinizin her aşaması için fırsatlar bulun.
              </p>
              <p className=" text-[rgb(92,91,91)] lg:md:text-xl text-[16px]   text-left mb-[25px] flex  gap-x-2">
                <div>
                  <FaCheckCircle className="w-5 h-5 mt-1 "></FaCheckCircle>
                </div>{" "}
                Ne zaman, nerede ve nasıl çalışacağınıza kendiniz karar verin.
              </p>
              <p className=" text-[rgb(92,91,91)] lg:md:text-xl text-[16px]   text-left mb-[25px] flex  gap-x-2">
                <div>
                  <FaCheckCircle className="w-5 h-5 mt-1 "></FaCheckCircle>{" "}
                </div>
                Kazanmak için KocFreelancing'i kontrol edin, fırsatları bulun.
              </p>
              <p className=" text-[rgb(92,91,91)] lg:md:text-xl text-[16px]   text-left mb-[25px] flex  gap-x-2">
                <div>
                  <FaCheckCircle className="w-5 h-5 mt-1 "></FaCheckCircle>{" "}
                </div>{" "}
                <p>
                  Unutma! KocFreelancing'e <b>kayıt</b> olurken herhangi bir
                  ücret ödemiyorsun. <b>Ayrıca </b>iş İlanlarına teklif verirken
                  ödediğin teklif ücretini de işi alamadığın taktirde{" "}
                  <b>geri iade</b> alıyorsun.
                </p>
              </p>
              <div className="flex items-center justify-center my-8">
                <Link
                  className=" rounded-3xl  !py-3 !px-8    bg-primary hover:bg-opacity-90 transition-all border !text-[#fff] text-center active:scale-95 "
                  to="/categories"
                >
                  Daha Fazlasını Keşfet
                </Link>
              </div>
            </div>
            <div
              style={{ width: "100%", height: "100%" }}
              className="hidden lg:md:block"
            >
              <img src="assets/img/body4.png" alt="body4" className="" />
            </div>
          </Stack>
        </Container>
      </div>
    </div>
  );
};
