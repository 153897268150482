import React from "react";
import ChatApp from "../../components/chat/ChatApp";
import Footer from "../../components/layouts/Footer";
import Navbar from "../../components/layouts/Navbar";

const Messages = () => {
  return (
    <div>
      <Navbar />
      <ChatApp />
      {/* <Footer /> */}
    </div>
  );
};

export default Messages;
