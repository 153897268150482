import { setLoading, setUserData } from "../redux/reducers/userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import toast from "react-hot-toast";
import genTestUserSig from "../helper/GenerateTestUserSig";
import Cookies from "js-cookie";

const baseURL = `https://api.kocfreelancing.com/api/v1`;
// const baseURL = "https://api.kocfreelancing.com/api/v1";

const axiosInstance = Axios.create({
  baseURL,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = localStorage.getItem("accessToken");
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const config = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

const configCT = { headers: { "Content-Type": "application/json" } };

export const asyncThunkCreator = (
  name,
  endpoint,
  method = "post",
  successToast = ""
) =>
  createAsyncThunk(
    name,
    async ({ dynamicParams, bodyData }, { rejectWithValue }) => {
      try {
        const validMethods = ["get", "post", "put", "patch", "delete"];
        if (!validMethods.includes(method.toLowerCase())) {
          throw new Error(`Invalid HTTP method: ${method}`);
        }

        let url = `${baseURL}${endpoint}`;
        Object.entries(dynamicParams).forEach(([key, value]) => {
          url = url.replace(`{${key}}`, value);
        });

        const response = await axiosInstance[method](url, bodyData, configCT);

        if (name === "auth/loginUser") {
          localStorage.setItem("accessToken", response.data.data.accessToken);
          localStorage.setItem("userId", response.data.data.user_id);
          Cookies.set("userId", response.data.data.user_id);
          Cookies.set("email", response.data.data.email);
          console.log(response);
        }

        if (successToast) toast.success(successToast);
        return response.data;
      } catch (error) {
        toast.error(
          error?.response?.data?.message || "Bir şeyler yanlış gitti!"
        );
        console.log(error);
        return rejectWithValue(
          error.response.data || error.message || "Bir şeyler yanlış gitti!"
        );
      }
    }
  );

export default asyncThunkCreator;

export const registerUser = asyncThunkCreator(
  "auth/registerUser",
  "/user/register",
  "post",
  "Başarıyla kayıt oldunuz!"
);
export const loginUser = asyncThunkCreator(
  "auth/loginUser",
  "/auth/login",
  "post",
  "Başarıyla giriş yaptınız!"
);
export const resendEmail = asyncThunkCreator(
  "auth/resendEmail",
  "/auth/resendEmail/{email}",
  "post",
  "E-posta başarıyla gönderildi!"
);
export const forgetPassword = asyncThunkCreator(
  "auth/forgetPassword",
  "/auth/forgetPassword/{email}",
  "patch",
  "Şifre sıfırlama bağlantısı e-postanıza gönderildi!"
);
export const resetPassword = asyncThunkCreator(
  "auth/resetPassword",
  "/auth/resetPassword/{token}",
  "patch",
  "Şifre başarıyla sıfırlandı!"
);
export const verifyEmail = asyncThunkCreator(
  "auth/verifyEmail",
  "/auth/verifyEmail/{token}",
  "patch"
);

export const getProfile = createAsyncThunk(
  "user/fetchUserData",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("No user ID found in localStorage");
      }
      const response = await axiosInstance.get(
        `${baseURL}/profile/${userId}`,
        configCT
      );
      dispatch(setUserData(response.data));
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const getProfileById = createAsyncThunk(
  "profile/fetchProfileData",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `${baseURL}/profile/${userId}`,
        configCT
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const editInfo = asyncThunkCreator(
  "user/editInfoData",
  "/profile/{userId}",
  "patch",
  "Başarıyla güncellendi!"
);
export const editProfile = asyncThunkCreator(
  "user/editProfileData",
  "/user/{userId}",
  "patch",
  "Başarıyla güncellendi!"
);
export const editSettings = asyncThunkCreator(
  "user/editSettingsData",
  "/misc/profile/{userId}",
  "patch",
  "Başarıyla güncellendi!"
);
export const editPhone = asyncThunkCreator(
  "user/editPhoneData",
  "/misc/phone/{userId}",
  "patch",
  "Başarıyla güncellendi!"
);

export const addExperience = asyncThunkCreator(
  "user/addExperienceData",
  "/profile/addExperience/{userId}",
  "post",
  "Deneyim başarıyla eklendi!"
);
export const editExperience = asyncThunkCreator(
  "user/editExperienceData",
  "/profile/editExperience/{userId}/{experienceId}",
  "patch",
  "Deneyim başarıyla güncellendi!"
);
export const deleteExperience = asyncThunkCreator(
  "user/deleteExperienceData",
  "/profile/removeExperience/{userId}/{experienceId}",
  "delete",
  "Deneyim başarıyla silindi!"
);

export const addLanguage = asyncThunkCreator(
  "user/addLanguageData",
  "/profile/addLanguage/{userId}",
  "post",
  "Dil başarıyla eklendi!"
);
export const deleteLanguage = asyncThunkCreator(
  "user/deleteLanguageData",
  "/profile/removeLanguage/{userId}/{languageId}",
  "delete",
  "Dil başarıyla silindi!"
);
export const editLanguage = asyncThunkCreator(
  "user/editLanguageData",
  "/profile/editLanguage/{userId}/{languageId}",
  "patch",
  "Dil başarıyla güncellendi!"
);

export const addProject = asyncThunkCreator(
  "user/addProjectData",
  "/profile/addProject/{userId}",
  "post",
  "Proje başarıyla eklendi!"
);
export const editProject = asyncThunkCreator(
  "user/editProjectData",
  "/profile/editProject/{userId}/{projectId}",
  "patch",
  "Proje başarıyla güncellendi!"
);
export const deleteProject = asyncThunkCreator(
  "user/deleteProjectData",
  "/profile/removeProject/{userId}/{projectId}",
  "delete",
  "Proje başarıyla silindi!"
);

export const addEducation = asyncThunkCreator(
  "user/addEducationData",
  "/profile/addEducation/{userId}",
  "post",
  "Eğitim bilgileriniz başarıyla eklendi!"
);
export const editEducation = asyncThunkCreator(
  "user/editEducationData",
  "/profile/editEducation/{userId}/{educationId}",
  "patch",
  "Eğitim başarıyla güncellendi!"
);
export const deleteEducation = asyncThunkCreator(
  "user/deleteEducationData",
  "/profile/removeEducation/{userId}/{educationId}",
  "delete",
  "Eğitim bilgileriniz başarıyla silindi!"
);

export const addJob = asyncThunkCreator(
  "user/addJobData",
  "/offer/create/",
  "post",
  "İş Başarıyla Eklendi!"
);
export const editJob = asyncThunkCreator(
  "user/editJobData",
  "/offer/{jobId}",
  "patch",
  "İş Başarıyla Düzenlendi!"
);
export const addBookmark = asyncThunkCreator(
  "user/addBookmark",
  "/bookmark/create/",
  "post",
  "Yer İmlerine Eklendi!"
);

export const deleteBookmark = asyncThunkCreator(
  "user/deleteBookmarkData",
  "/bookmark/{bookmarkId}",
  "delete",
  "Yer İmlerinden Kaldırıldı!"
);
export const addProposal = asyncThunkCreator(
  "user/addProposal",
  "/proposal/create/",
  "post",
  "Teklif Başarıyla Gönderildi!"
);
export const editProposal = asyncThunkCreator(
  "user/editProposalData",
  "/proposal/{proposalId}",
  "patch",
  "Teklifiniz başarıyla güncellendi!"
);
export const acceptProposal = asyncThunkCreator(
  "user/acceptProposalData",
  "/proposal/accept/{proposalId}/{jobId}",
  "patch",
  "Teklif Başarıyla Kabul Edildi!"
);
export const acceptJob = asyncThunkCreator(
  "user/acceptProposalData",
  "/offer/acceptInvitation/{jobId}/{userId}",
  "patch",
  "İş Başarıyla Kabul Edildi!"
);
export const deleteProposal = asyncThunkCreator(
  "user/deleteProposalData",
  "/proposal/{proposalId}",
  "delete",
  "Teklif Başarıyla Silindi!"
);
export const requestCompletion = asyncThunkCreator(
  "user/requestCompletionData",
  "/offer/requestCompletion/{id}",
  "post",
  "Müşteriden işi tamamlandı olarak işaretlemesi istendi!"
);
export const markAsCompleted = asyncThunkCreator(
  "user/requestCompletionData",
  "/offer/complete/{jobId}/{userId}",
  "patch",
  "İş Başarıyla Tamamlandı!"
);
export const markInviteAsCompleted = asyncThunkCreator(
  "user/requestCompletionData",
  "/offer/completeInvitedJob/{jobId}/{userId}",
  "patch",
  "İş Başarıyla Tamamlandı!"
);
export const giveClientReview = asyncThunkCreator(
  "user/requestCompletionData",
  "/offer/addClientReview/{userId}/{jobId}",
  "post",
  "Müşteriye İnceleme Verildi!"
);
export const cancelJob = asyncThunkCreator(
  "user/cancelJobData",
  "/offer/cancel/{jobId}",
  "delete",
  "İş Başarıyla İptal Edildi!"
);
export const deleteJob = asyncThunkCreator(
  "user/deleteJobData",
  "/offer/{jobId}",
  "delete",
  "İş Başarıyla Silindi!"
);
export const hireNow = asyncThunkCreator(
  "user/requestCompletionData",
  "/offer/hireMe/{id}",
  "post",
  "Çalışana iş teklifi başarıyla gönderildi!"
);

export const addPayment = asyncThunkCreator(
  "user/addPaymentData",
  "/profile/payment-info/{userId}",
  "patch",
  "Ödeme Başarıyla Eklendi"
);

// Async thunk action creators for job-related actionse
export const getJobById = createAsyncThunk(
  "job/fetchJobById",
  async ({ jobId, userId, sort }, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/specific/${jobId}/${userId}?proposalSort=${sort}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getJobs = createAsyncThunk(
  "job/fetchJobData",
  async (
    {
      user_id,
      minBudget = "",
      maxBudget = "",
      project_size = "",
      sortOrder = "",
      sortBy = "",
      subCategory = "",
    },
    thunkAPI
  ) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/${user_id}?minBudget=${minBudget}&maxBudget=${maxBudget}&project_size=${project_size}&sortOrder=${sortOrder}&sortBy=${sortBy}&subCategory=${subCategory}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getAllJobs = createAsyncThunk(
  "job/fetchAllJobsData",
  async (
    {
      user_id,
      searchTerm = "",
      minBudget = "",
      maxBudget = "",
      project_size = "",
      sortOrder = "",
      sortBy = "",
      category = "",
      subCategory = "",
    },
    thunkAPI
  ) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/all/${user_id}?searchTerm=${searchTerm}&minBudget=${minBudget}&maxBudget=${maxBudget}&project_size=${project_size}&sortOrder=${sortOrder}&sortBy=${sortBy}&category=${category}&subCategory=${subCategory}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getBookmarks = createAsyncThunk(
  "job/fetchBookmarks",
  async (userId, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/bookmark/user/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getMyJobs = createAsyncThunk(
  "job/fetchMyJobs",
  async (userId, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/user/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk action creators for category-related actions
export const getCategories = createAsyncThunk(
  "category/fetchCategoryData",
  async (search, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/category?searchTerm=${search}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getPopularCategories = createAsyncThunk(
  "category/fetchCategoryData",
  async (_, thunkAPI) => {
    try {
      const response = await Axios.get(`${baseURL}/category/popular`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getCategoriesById = createAsyncThunk(
  "category/fetchCategoryById",
  async (
    {
      id,
      search = "",
      country = "",
      overall_rating = "",
      min_hourly_rate = "",
      max_hourly_rate = "",
      subCategory = "",
    },
    thunkAPI
  ) => {
    try {
      const response = await Axios.get(
        `${baseURL}/category/${id}?searchTerm=${search}&country=${country}&overall_rating=${overall_rating}&min_hourly_rate=${min_hourly_rate}&max_hourly_rate=${max_hourly_rate}&subCategory=${subCategory}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getSubCategoriesById = createAsyncThunk(
  "category/fetchCategoryById",
  async (
    {
      id,
      search = "",
      country = "",
      overall_rating = "",
      min_hourly_rate = "",
      max_hourly_rate = "",
    },
    thunkAPI
  ) => {
    try {
      const response = await Axios.get(
        `${baseURL}/subCategory/category/${id}?searchTerm=${search}&country=${country}&overall_rating=${overall_rating}&min_hourly_rate=${min_hourly_rate}&max_hourly_rate=${max_hourly_rate}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getSkillsById = createAsyncThunk(
  "category/fetchCategoryById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/skill/category/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getSpecificSubCategoryById = createAsyncThunk(
  "category/fetchCategoryById",
  async (
    {
      id,
      search = "",
      country = "",
      overall_rating = "",
      min_hourly_rate = "",
      max_hourly_rate = "",
    },
    thunkAPI
  ) => {
    try {
      const response = await Axios.get(
        `${baseURL}/subCategory/category/${id}?searchTerm=${search}&country=${country}&overall_rating=${overall_rating}&min_hourly_rate=${min_hourly_rate}&max_hourly_rate=${max_hourly_rate}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getEmployeesByCategory = createAsyncThunk(
  "category/fetchEmployeesByCategory",
  async (id, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/misc/employees/${id}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk action creators for country actions
export const getCountries = createAsyncThunk(
  "country/fetchCountryData",
  async (_, thunkAPI) => {
    try {
      const response = await Axios.get(`${baseURL}/misc/countries`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
// Async thunk action creators for skills actions
export const getSkills = createAsyncThunk(
  "skills/fetchSkillsData",
  async (_, thunkAPI) => {
    try {
      const response = await Axios.get(`${baseURL}/skill`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getHires = createAsyncThunk(
  "Hires/fetchSkillsData",
  async ({ userId, search, status }, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/hired/${userId}?searchTerm=${search}&status=${status}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getClients = createAsyncThunk(
  "Clients/fetchSkillsData",
  async ({ userId, search, status }, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/offer/client/${userId}?searchTerm=${search}&status=${status}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getNotifications = createAsyncThunk(
  "Clients/fetchSkillsData",
  async (userId, thunkAPI) => {
    try {
      const response = await Axios.get(
        `${baseURL}/notification/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const patchReadNotifications = createAsyncThunk(
  "Clients/fetchSkillsData",
  async (userId, thunkAPI) => {
    try {
      const response = await Axios.patch(
        `${baseURL}/notification/readNotification/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const postInitiateChat = createAsyncThunk(
  "Chat/fetchSkillsData",
  async (userId, thunkAPI) => {
    try {
      const response = await Axios.post(
        `https://adminapisgp.im.qcloud.com/v4/im_open_login_svc/account_import?sdkappid=20008771&identifier=administrator&usersig=eJwtzFsLgkAQBeD-Ms*Rq7GtCb0FmUQFWkn4Is0oQ3lbF4mi-15eHs93DucD0T6cd6TBA2cuYDZkRioNZzxwigWX3BqdmkpPgxYfaV0zgucIIVyl7NHpVbMm8GwpZd*MarjoTdlyoZauO2nL*f-9HlPldxhsskN58ilKrLfY5gE2VSPUMYzxUjwTi3B1ve3Oa-j*AH0mNYU_&random=99999999&contenttype=json`,
        { UserID: userId }
      );
      console.log(response);
      if (response.status === 200) {
        const genSig = genTestUserSig(userId);
        Cookies.set("userSig", genSig.userSig);
        console.log(genSig);
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getTerms = createAsyncThunk(
  "Clients/fetchSkillsData",
  async (_, thunkAPI) => {
    try {
      const response = await Axios.get(`${baseURL}/misc/terms`, config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
// Payment related async thunk action creators

export const getTransactions = createAsyncThunk(
  "profile/fetchTransactionData",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await Axios.get(
        `${baseURL}/transaction/${userId}`,
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
