import React, { useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import EducationModal from "../modals/EducationModal";
import { FiPlus } from "react-icons/fi";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { RxCrossCircled } from "react-icons/rx";
import Modal from "../ui/Modal";
import { AnimatePresence } from "framer-motion";
import LanguageModal from "../modals/LanguageModal";
import DeleteModal from "../modals/DeleteModal";
import { setLoading } from "../../redux/reducers/loadingSlice";
import OtpModal from "../modals/OtpModal";
import PaymentModal from "../modals/PaymentModal";
import { deleteEducation, deleteLanguage, getProfile } from "../../axios/axios";
import { tr } from "date-fns/locale";
import Description from "../ui/Description";
import axios from "axios";
const ProfileHeader = ({ userProfile, isMine }) => {
  const profile = useSelector((state) => state.user.data);
  // State variables for modals
  const [showAddLanguageModal, setShowAddLanguageModal] = useState(false);
  const [showDeleteLanguageModal, setShowDeleteLanguageModal] = useState(null);
  const [showEditLanguageModal, setShowEditLanguageModal] = useState(null);
  const [languageEditData, setLanguageEditData] = useState({
    name: "",
    efficiency: "",
  });
  const [showAddEducationModal, setShowAddEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(null);
  const [showDeleteEducationModal, setShowDeleteEducationModal] =
    useState(null);
  const [editEducationData, setEditEducationData] = useState({
    school: "",
    degree: "",
    field_of_study: "",
    from: null,
    to: null,
    current: true,
    description: "",
  });
  const [paymentEditData, setPaymentEditData] = useState({
    first_name: "",
    last_name: "",
    bank_name: "",
    iban_number: "",
  });
  const [otpModal, setOtpModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [editPaymentModal, setEditPaymentModal] = useState(null);
  // Redux state for loading
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  const [generatedOtp, setGeneratedOtp] = useState(null);

  // Function to handle deletion of a language
  const handleDeleteLanguage = () => {
    dispatch(setLoading(true));
    dispatch(
      deleteLanguage({
        dynamicParams: {
          userId: userProfile?.user?._id,
          languageId: showDeleteLanguageModal,
        },
        bodyData: null,
      })
    )
      .then(() => dispatch(getProfile()))
      .then(() => {
        dispatch(setLoading(false));
        setShowDeleteLanguageModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setLoading(false));
      });
  };

  // Function to handle deletion of an education
  const handleDeleteEducation = () => {
    dispatch(setLoading(true));
    dispatch(
      deleteEducation({
        dynamicParams: {
          userId: userProfile?.user?._id,
          educationId: showDeleteEducationModal,
        },
        bodyData: null,
      })
    )
      .then(() => dispatch(getProfile()))
      .then(() => {
        dispatch(setLoading(false));
        setShowDeleteEducationModal(null);
      })
      .catch((error) => {
        console.error("Error:", error);
        dispatch(setLoading(false));
      });
  };

  const formatBalance = (balance) => {
    if (balance >= 1_000_000_000) {
      return (balance / 1_000_000_000).toFixed(1) + "B";
    } else if (balance >= 1_000_000) {
      return (balance / 1_000_000).toFixed(1) + "M";
    } else if (balance >= 1_000) {
      return (balance / 1_000).toFixed(1) + "k";
    } else {
      return balance.toFixed(2);
    }
  };


  const sendOTP = async () => {
    // // Generate a random number between 100000 and 999999
    // const otp = Math.floor(100000 + Math.random() * 900000);
    // setGeneratedOtp(otp);

    // // JSON data to be sent in the POST request
    // const requestData = {
    //   mainbody: {
    //     header: {
    //       usercode: "8503080217",
    //       password: "A18.D46",
    //       msgheader: "KOCFREELANCING ",
    //       appkey: "b3d0c76a82a5672406ef8725d667d825"
    //     },
    //     body: {
    //       msg: `
    //       KOCFREELANCING
    //     Telefon numaranızı doğrulamak için kullanmanız gereken doğrulama kodunuz: ${generatedOtp}
    //     Bu kodu, hesabınızı güvence altına almak için kimseyle paylaşmayın.
    //     `,  // Use the generated OTP here
    //       no: userProfile?.user.phone_number, // Destination number
    //     }
    //   }
    // };

    try {
    //   const res = await axios.post(`https://api.netgsm.com.tr/sms/send/otp`, requestData, {
    //     headers: {
    //       'Content-Type': 'application/json' // Ensure the correct content type
    //     }
    //   });

    //   console.log(res)
    //   if (res.data.payload.statusCode === 200 || res.data.payload.statusCode === 201) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/auth/phoneOtp`, {
          user_id: userProfile?.user._id
        })

        console.log(response)
      // }

    } catch (e) {
      console.log('Error sending OTP:', e);
    }
  };


  console.log(userProfile.user.phone_verified)

  return (
    <div className="col-span-1 border-b lg:border-b-0 border-r ">
      {/* Stats section */}
      <div className="grid  xl:grid-cols-3 grid-cols-2   gap-2 place-items-center items-center border-b p-3">
        {[
          {
            label: "Toplam Kazanç",
            value: `₺${formatBalance(userProfile?.amount_earned ?? 0)}`,
          },
          {
            label: "Tamamlanan işler",
            value: userProfile?.completed_projects ?? 0,
          },
          {
            label: "Aktif işler",
            value: userProfile?.active_projects ?? 0,
          },
          {
            label: "Toplam Harcama",
            value: `₺${formatBalance(userProfile?.amount_spent ?? 0)}`,
          },
          {
            label: "Toplam İşe Alım",
            value: `${userProfile?.total_hired ?? 0}`,
          },
          {
            label: "Yayınlanan işler",
            value: `${userProfile?.posted_projects ?? 0}`,
          },
        ].map((stat, index) => (
          <div key={index}>
            <p className="break-all text-2xl font-semibold text-center">
              {stat.value}
            </p>
            <p className="break-all text-sm text-secondary">{stat.label}</p>
          </div>
        ))}
      </div>

      {/* Languages section */}
      <div className="p-6">
        <div className="mb-5">
          <div className="flex items-center justify-between">
            <p className="break-all lg:md:text-2xl text-xl font-semibold mb-2">
              Diller
            </p>
            <div
              className={`rounded-full p-[3px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all ${!isMine && "hidden"
                }`}
              onClick={() => setShowAddLanguageModal(true)}
            >
              <FiPlus className="text-primary text-lg" />
            </div>
          </div>
          {userProfile?.languages?.length > 0 ? (
            userProfile?.languages?.map((language, index) => (
              <div className="flex items-center justify-between" key={index}>
                <p className="break-all font-medium mb-2">
                  {language?.name}:{" "}
                  <span className="font-normal">{language?.efficiency}</span>
                </p>
                <div
                  className={`flex items-center gap-x-2 ${!isMine && "hidden"}`}
                >
                  {[
                    {
                      Icon: MdEdit,
                      onClick: () => {
                        setShowEditLanguageModal(language?._id);
                        setLanguageEditData({
                          name: language?.name,
                          efficiency: language?.efficiency,
                        });
                      },
                    },
                    {
                      Icon: MdDelete,
                      onClick: () => setShowDeleteLanguageModal(language?._id),
                    },
                  ].map((action, index) => (
                    <div
                      key={index}
                      className="rounded-full p-[3px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all"
                    >
                      <action.Icon
                        className="text-primary text-lg"
                        onClick={action.onClick}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))
          ) : (
            <p className="break-all text-secondary my-2">
              Gösterilecek veri yok
            </p>
          )}
        </div>

        {/* Contact info section */}
        {isMine || profile?.role === "admin" ? (
          <div className="mb-5">
            <p className="break-all lg:md:text-2xl text-xl font-semibold mb-2">
              İletişim bilgileri
            </p>
            {[
              {
                label: "Telefon Numarası",
                data: userProfile?.user?.phone_number,
              },
              {
                label: "E-mail Adresi",
                data: userProfile?.user?.email,
              },
            ].map((item, index) => (
              <p key={index} className="font-medium mb-2 flex items-center ">
                <span className="whitespace-nowrap ">{item.label}</span>:{" "}
                {item.data ? (
                  <span
                    className="mx-1 font-normal overflow-x-scroll py-[1px] no-scrollbar"
                    style={{ userSelect: "text" }}
                  >
                    {item.data}
                  </span>
                ) : (
                  <span className="mx-1 font-normal">Eklenmedi</span>
                )}
              </p>
            ))}
          </div>
        ) : (
          ""
        )}
        {/* Payment info section */}
        {isMine || profile?.role === "admin" ? (
          <div className="mb-5">
            <div className=" mb-2 flex items-center justify-between">
              <p className="break-all text-2xl font-semibold">
                Ödeme bilgileri
              </p>{" "}
              <MdEdit
                className="text-primary cursor-pointer"
                onClick={() => {
                  setEditPaymentModal(userProfile?.user?._id);
                  setPaymentEditData({
                    first_name: userProfile?.payment?.first_name,
                    last_name: userProfile?.payment?.last_name,
                    bank_name: userProfile?.payment?.bank_name,
                    iban_number: userProfile?.payment?.iban_number,
                  });
                }}
              />
            </div>
            {[
              {
                label: "Ad",
                data: userProfile?.payment?.first_name,
              },
              {
                label: "Soyad",
                data: userProfile?.payment?.last_name,
              },
              {
                label: "Banka Adı",
                data: userProfile?.payment?.bank_name,
              },
              {
                label: "IBAN Numarası",
                data: userProfile?.payment?.iban_number,
              },
            ].map((item, index) => (
              <p key={index} className="font-medium mb-2 flex items-center ">
                <span className="whitespace-nowrap ">{item.label}</span>:{" "}
                {item.data ? (
                  <span
                    className="mx-1 font-normal overflow-x-scroll py-[1px] no-scrollbar"
                    style={{ userSelect: "text" }}
                  >
                    {item.data}
                  </span>
                ) : (
                  <span className="mx-1 font-normal">Eklenmedi</span>
                )}
              </p>
            ))}
          </div>
        ) : (
          ""
        )}
        {/* Doğrulamalar section */}
        <div className="mb-5">
          <p className="break-all lg:md:text-2xl text-xl font-semibold mb-2">
            Doğrulamalar
          </p>
          {[
            { label: "E-mail", verified: userProfile?.user?.is_verified },
            { label: "Telefon", verified: userProfile?.user.phone_verified },
            { label: "Ödeme", verified: userProfile?.payment_verified },
          ].map((verification, index) => (
            <div
              className="flex items-center justify-between w-full "
              key={index}
            >
              <div className="flex items-center w-full whitespace-nowrap overflow-hidden text-ellipsis">
                <p className="font-medium flex items-center whitespace-nowrap overflow-hidden text-ellipsis">
                  {verification.label}:{" "}
                  {verification.verified ? (
                    <>
                      <span className="mx-1 font-normal">Doğrulandı</span>
                      <RiVerifiedBadgeFill />
                    </>
                  ) : (
                    <>
                      <span className="mx-1 font-normal whitespace-nowrap overflow-hidden text-ellipsis">
                        Doğrulanmadı
                      </span>
                    </>
                  )}
                </p>
              </div>
              {isMine
                ? !verification.verified && (
                  <p
                    className="text-primary hover:underline cursor-pointer"
                    onClick={() => {
                      if (verification.label === "Telefon") {
                        sendOTP()
                        setOtpModal(true);
                      } else if (verification.label === "Ödeme") {
                        setPaymentModal(true);
                      }
                    }}
                  >
                    Doğrula
                  </p>
                )
                : ""}
            </div>
          ))}
        </div>

        {/* Education section */}
        <div className="mb-5">
          <div className="flex items-center justify-between">
            <p className="break-all lg:md:text-2xl text-xl font-semibold mb-2">
              Eğitim
            </p>
            <div
              className={`rounded-full p-[3px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all ${!isMine && "hidden"
                }`}
              onClick={() => setShowAddEducationModal(true)}
            >
              <FiPlus className="text-primary text-lg" />
            </div>
          </div>
          {userProfile?.education?.length > 0 ? (
            userProfile?.education?.map((education, index) => (
              <div className="mb-2" key={index}>
                <div className="flex items-center justify-between">
                  <p className="break-all font-medium  text-xl">
                    {education?.school}
                  </p>
                  <div
                    className={`flex items-center gap-x-2 ${!isMine && "hidden"
                      }`}
                  >
                    {[
                      {
                        Icon: MdEdit,
                        onClick: () => {
                          setShowEditEducationModal(education?._id);
                          setEditEducationData({
                            school: education?.school,
                            field_of_study: education?.field_of_study,
                            degree: education?.degree,
                            current: education?.current,
                            description: education?.description,
                          });
                        },
                      },
                      {
                        Icon: MdDelete,
                        onClick: () =>
                          setShowDeleteEducationModal(education?._id),
                      },
                    ].map((action, index) => (
                      <div
                        key={index}
                        className="rounded-full p-[3px] border-[1px] border-primary hover:bg-gray-50 cursor-pointer transition-all"
                      >
                        <action.Icon
                          className="text-primary text-lg"
                          onClick={action.onClick}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <p className="break-all   text-lg text-secondary">
                  {format(new Date(education?.from), "MMMM, yyyy", {
                    locale: tr,
                  })}{" "}
                  -{" "}
                  {education?.current
                    ? "Hala burada çalışıyor"
                    : format(new Date(education?.to), "MMMM, yyyy", {
                      locale: tr,
                    })}
                </p>
                <p className="break-all   text-lg text-secondary">
                  {education?.degree}, {education?.field_of_study}
                </p>
                <Description
                  description={
                    education?.description ? education?.description : ""
                  }
                  maxLines={1}
                  maxWords={20}
                  className={"text-secondary"}
                />
              </div>
            ))
          ) : (
            <p className="break-all  text-secondary my-2">
              Gösterilecek veri yok
            </p>
          )}
        </div>
      </div>

      {/* Modals for adding, editing, and deleting languages and education */}
      <AnimatePresence initial={false} onExitComplete={() => null}>
        {showAddLanguageModal && (
          <LanguageModal
            setShowAddLanguageModal={setShowAddLanguageModal}
            showAddLanguageModal={showAddLanguageModal}
            userProfile={userProfile}
          />
        )}
        {showEditLanguageModal && (
          <LanguageModal
            setShowAddLanguageModal={setShowEditLanguageModal}
            showAddLanguageModal={showEditLanguageModal}
            initialData={languageEditData}
            userProfile={userProfile}
            isEdit={true}
          />
        )}
        {showDeleteLanguageModal && (
          <DeleteModal
            loading={loading}
            onClose={() => setShowDeleteLanguageModal(null)}
            onConfirm={() => handleDeleteLanguage()}
          />
        )}
        {showAddEducationModal && (
          <EducationModal
            setShowEducationModal={setShowAddEducationModal}
            showEducationModal={showAddEducationModal}
            userProfile={userProfile}
          />
        )}
        {showEditEducationModal && (
          <EducationModal
            setShowEducationModal={setShowEditEducationModal}
            showEducationModal={showEditEducationModal}
            userProfile={userProfile}
            initialData={editEducationData}
            isEdit={true}
          />
        )}
        {showDeleteEducationModal && (
          <DeleteModal
            loading={loading}
            onClose={() => setShowDeleteEducationModal(null)}
            onConfirm={() => handleDeleteEducation()}
          />
        )}
        {otpModal && (
          <OtpModal
            setOtpModal={setOtpModal}
            otpModal={otpModal}
            userProfile={userProfile}
          />
        )}
        {paymentModal && (
          <PaymentModal
            setPaymentModal={setPaymentModal}
            paymentModal={paymentModal}
            userProfile={userProfile}
          />
        )}
        {editPaymentModal && (
          <PaymentModal
            setPaymentModal={setEditPaymentModal}
            paymentModal={editPaymentModal}
            isEdit={true}
            userProfile={userProfile}
            initialData={paymentEditData}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ProfileHeader;
