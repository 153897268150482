import React from "react";
import { IoMegaphoneSharp } from "react-icons/io5";
import { FaCheckDouble } from "react-icons/fa";
import { FaWpforms } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const Steps = () => {
  return (
    <div className="w-full overflow-x-hidden bg-[whitesmoke] px-0 lg:md:py-[45px] py-7">
      <div className="max-w-[1400px] w-[95vw] flex items-center justify-around mx-auto my-0">
        <div className=" ">
          <h1 className="text-[rgb(53,185,0)] text-xl pl-[25px] lg:md:pl-[35px] lg:md:text-3xl capitalize mb-5  font-bold text-center lg:text-left">
            3 ADIMDA İŞİNİZİ HALLEDİN
          </h1>
          <p className="flex items-center gap-x-4 lg:md:w-10/12 text-[rgb(92,91,91)] lg:md:text-xl text-[16px]  mb-[15px] pl-[25px] lg:md:pl-[35px] text-left">
            <FaWpforms className="lg:md:text-2xl text-xl  rounded-[100px] " />{" "}
            KocFreelancing'e kayıt olun.
          </p>
          <p className="flex items-center gap-x-4 lg:md:w-10/12 text-[rgb(92,91,91)] lg:md:text-xl text-[16px]  mb-[15px] pl-[25px] lg:md:pl-[35px] text-left">
            <IoMegaphoneSharp className="lg:md:text-2xl text-xl  rounded-[100px] " />{" "}
            İşiniz için ilan verin.
          </p>
          <p className="flex items-center gap-x-4 lg:md:w-10/12 text-[rgb(92,91,91)] lg:md:text-xl text-[16px]  pl-[25px] lg:md:pl-[35px] text-left">
            <FaCheckDouble className="lg:md:text-2xl text-xl  rounded-[100px] " />{" "}
            Gelen tekliflerden işinize uygun olan profesyoneli seçin.
          </p>
          &nbsp;{" "}
          <div className="flex items-center justify-center my-2">
            <Link
              className=" rounded-3xl  py-3 px-8    bg-primary hover:bg-opacity-90 transition-all border !text-white text-center active:scale-95 "
              to="/categories"
            >
              Şimdi Keşfet
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
