import React, { useEffect, useRef, useState } from "react";
import Modal from "../ui/Modal";
import { RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { addLanguage, editLanguage, getProfile } from "../../axios/axios";
import { setLoading } from "../../redux/reducers/loadingSlice";
import { useForm } from "react-hook-form";
import { Typography } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const OtpModal = ({ setOtpModal, userProfile, otpModal }) => {
  const dispatch = useDispatch();
  const [generatedOtp, setGeneratedOtp] = useState(null);
  const loading = useSelector((state) => state.loading.loading);
  const [timer, setTimer] = useState(60);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [disableResendButton, setDisableResendButton] = useState(false);
  const inputs = useRef([]);
  console.log(otp, inputs);
  const handleChange = (e, index) => {
    const { value } = e.target;
    // Allow only numeric characters
    const numericValue = value.replace(/\D/g, "");
    const newOTP = [...otp];
    newOTP[index] = numericValue.slice(-1); // Update OTP array
    setOtp(newOTP);
    // Move focus to the next input
    if (value !== "" && index < 5) {
      inputs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    if (!otpModal) {
      setOtp(["", "", "", "", "", ""]); // Reset form when modal is closed
    }
  }, [otpModal]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setDisableResendButton(false);
      clearInterval(interval);
    }
    if (timer > 60 || timer < 0) {
      setTimer(60);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleResendOTP = async () => {
    setLoading({ ...loading, resendEmail: true });
    // Generate a random number between 100000 and 999999
    const otp = Math.floor(100000 + Math.random() * 900000);
    setGeneratedOtp(otp);

    // JSON data to be sent in the POST request
    const requestData = {
      mainbody: {
        header: {
          usercode: "8503080217",
          password: "A18.D46",
          msgheader: "KOCFREELANCING ",
          appkey: "b3d0c76a82a5672406ef8725d667d825",
        },
        body: {
          msg: `
         Doğrulama Kodunuz: ${generatedOtp}

          Telefon numaranızı doğrulamak için kullanmanız gereken doğrulama kodunuz: 
          Bu kodu, hesabınızı güvence altına almak için kimseyle paylaşmayın.
          `, // Use the generated OTP here
          no: userProfile?.user.phone_number, // Destination number
        },
      },
    };

    try {
      const res = await axios.post(
        `https://api.netgsm.com.tr/sms/send/otp`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json", // Ensure the correct content type
          },
        }
      );

      console.log(res);
      if (
        res.data.payload.statusCode === 200 ||
        res.data.payload.statusCode === 201
      ) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/phoneOtp`,
          {
            user_id: userProfile?.user._id,
            otp: otp,
          }
        );
        // Start the timer
        setTimer(60);
        setDisableResendButton(true);
        console.log(response);
      }

      console.log("OTP sent successfully:", res.data);
    } catch (e) {
      toast.error("Oops, Bir şeyler ters gitti!");
      console.log("Error sending OTP:", e);
    }
    setLoading({ ...loading, resendEmail: false });
  };
  const submitOTP = async () => {
    setLoading({ ...loading, resendEmail: true });

    try {
      // Join the OTP array into a string
      const otpString = otp.join("");

      // Convert the OTP string to a number
      const otpNumber = Number(otpString);

      // Check if the conversion was successful
      if (isNaN(otpNumber)) {
        throw new Error("Invalid OTP number");
      }

      const response = await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/auth/verifyPhone`,
        {
          user_id: userProfile?.user?._id,
          otp: otpNumber, // Send OTP as a number
        }
      );

      if (response.status === 200 || response.status === 201) {
        toast.success("OTP başarıyla gönderildi!"); // Success message
        setOtpModal(false); // Close OTP modal
      }

      console.log("Response:", response);
    } catch (error) {
      toast.error("Oops, Bir şeyler ters gitti!"); // Error message
      console.error("Resend email failed:", error); // Log the error
    } finally {
      setLoading({ ...loading, resendEmail: false }); // Reset loading state
    }
  };

  return (
    <Modal>
      <div className="lg:md:p-8 p-4 w-[90vw] rounded-2xl bg-white lg:md:min-w-[480px] md:max-w-lg ">
        <div className="flex flex-col max-h-[80vh] p-1 overflow-y-auto">
          <div className="flex items-center justify-between">
            <p className="break-all lg:md:text-2xl text-xl font-semibold">
              Telefon numaranızı doğrulayınız
            </p>
            <RxCross1
              className="text-2xl cursor-pointer"
              onClick={() => setOtpModal(false)}
            />
          </div>
          <div className="flex justify-center items-center py-8">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="w-8 xs:w-10 lg:w-12 h-8 xs:h-10 lg:h-12 mx-1 text-center border rounded focus:outline-none focus:border-blue-500"
                ref={(ref) => (inputs.current[index] = ref)}
              />
            ))}
          </div>
          <Typography>
            OTP almadınız mı?{" "}
            {timer < 1 ? (
              <span
                className={`text-primary underline cursor-pointer ${
                  disableResendButton ? "hidden" : ""
                }`}
                onClick={() => handleResendOTP()}
              >
                OTP'yi tekrar gönder.
              </span>
            ) : (
              <span>{`${timer} saniye içinde OTP'yi tekrar gönderin.`}</span>
            )}
          </Typography>
        </div>

        <div className="flex items-center justify-between space-x-2 pt-3 border-t">
          <button
            type="button"
            className="px-4 py-3 font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none w-full"
            onClick={() => setOtpModal(false)}
          >
            İptal
          </button>
          <button
            type="submit"
            className={`px-4  font-medium text-white bg-primary border border-transparent rounded-md shadow-sm hover:bg-opacity-90 transition-all focus:outline-none w-full ${
              loading | otp.some((digit) => digit === "")
                ? "cursor-not-allowed py-[14px] opacity-50"
                : "py-[12px]"
            }`}
            disabled={loading | otp.some((digit) => digit === "")}
            onClick={() => submitOTP()}
          >
            {loading ? (
              <div className="loaderProfile mx-auto "></div>
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OtpModal;
